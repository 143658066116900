import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Card, 
  Button,
  ButtonGroup,
  CardImg,
  CardTitle, 
  CardBody,
  CardSubtitle,
  CardText,
  CardFooter
} from 'reactstrap'
import isEmpty from '../../validation/is-empty';

class Screen extends Component {
  constructor(){
    super()
    this.state={
      activeIndex:0,
      animating:false
    }
    this._setAnimation = this._setAnimation.bind(this)
    this._next = this._next.bind(this)
    this._previous = this._previous.bind(this)
    
  }
  _next=(len)=>{
    if(this.state.animating) return ;
    const nextIndex = this.state.activeIndex === len -1 ? 0 : this.state.activeIndex + 1
    this.setState({activeIndex:nextIndex})
  }
  _previous = (len) => {
    if(this.state.animating) return ;
    const nextIndex = this.state.activeIndex === 0 ? len -1 : this.state.activeIndex -1
    this.setState({activeIndex:nextIndex})
  }
  _setAnimation = (animating) => {
    this.setState({animating})
  }
  _goToIndex=(newIndex)=>{
    if(this.state.animating) return ;
    this.setState({activeIndex:newIndex})
  }
  render() {
    const {event,current} =this.props
    return (
      <Col style={{background:`url(${event.MainLayout.background}) no-repeat center center`}} className="bd-sidebar">
        <Row>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center text-light">{ isEmpty(event.DanhGia) ? "" : event.DanhGia[current].Name}</h2>
          </Col>
        </Row>
        <Row>
          {isEmpty(event.DanhGia) ? "" : isEmpty(event.DanhGia[current].NgDanhGia) ? "" : 
            event.DanhGia[current].NgDanhGia.map((ngdg,index) => 
            <Col xs={4} key={index}>
              <Card className="mt-1">
                <CardImg top width="100%" src={ngdg.Avatar} alt="Card image cap" />
                <CardBody>
                  <CardTitle>{ngdg.FullName}</CardTitle>
                  <CardSubtitle>{ngdg.Email}</CardSubtitle>
                  <CardText>
                    {ngdg.DonVi}<br/> 
                    {ngdg.PhongBan}<br/>
                  </CardText>
                </CardBody>
                <CardFooter className="text-center">
                  <ButtonGroup>
                    <Button color="secondary">Thấp</Button>
                    <Button color="success">Trung bình</Button>
                    <Button color="danger">Cao</Button>
                  </ButtonGroup>
                </CardFooter>
              </Card>
            </Col>
          )
          }
          
        </Row>
      </Col>
    )
  }
}

Screen.propTypes = {
  event: PropTypes.object.isRequired
};

export default Screen