import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withRouter } from 'react-router-dom'
import {Media,Row,Col,Card,CardBody,Button} from 'reactstrap'
import {getCurrentEvents} from '../../actions/eventActions'
import isEmpty from '../../validation/is-empty';

 class ViewContents extends Component {
   constructor(){
     super()
    this.onClick = this.onClick.bind(this)
   }
   onClick=(e)=>{
    localStorage.setItem("EventID",e)
    this.props.getCurrentEvents({id:e},this.props.history)
   }
  render() {
    const {loading,events} = this.props.events
    let content;
    if(loading|isEmpty(events)){
      content= null
    } else {
      content = events.map((result,index) => (
        <Col xs="3" className="pt-2">
          <Card >
            <CardBody style={{background:`url(${result.background}) no-repeat center center`,backgroundSize:"100% 100%"}}>
              <Row style={{height:'9vh'}}>
                <Col style={{height:'4vh'}}>
                  <Media style={{height:'100%'}} src={result.HeaderLeft}/>
                </Col>
                <Col style={{height:'4vh'}}>
                  <Media style={{height:'100%'}} src={result.HeaderCenter}/>
                </Col>
                <Col style={{height:'4vh'}}>
                  <Media style={{height:'100%'}} src={result.HeaderRight}/>
                </Col>
              </Row>
              <h2 className="text-center" style={{color:result.FontColor}}>{result.EventType}</h2>
              <Button block onClick={()=>this.onClick(result._id)}>Xem</Button>
            </CardBody>
          </Card>
        </Col>
      ))
    }
    return (
      <React.Fragment>
        {content}
      </React.Fragment>
      
    )
  }
}


ViewContents.propTypes = {
  getCurrentEvents:PropTypes.func.isRequired,
  events: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  events: state.events
});

export default withRouter(connect(mapStateToProps,{getCurrentEvents})(ViewContents))