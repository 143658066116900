import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Card,CardBody,CardFooter,CardTitle,Button,Col,Row,Table} from 'reactstrap'
import isEmpty from '../../validation/is-empty';

class ShortLink extends Component{
  constructor(){
    super()
    this.textarea = React.createRef()
    this._copy = this._copy.bind(this)
  }
  _copy(e){
    this.textarea.current.select()
    document.execCommand('copy')
  }
  render(){
    const {element} = this.props
    return(
      <Col>
        <Card>
          <CardTitle>{element.GiamKhao.FullName}</CardTitle>
          <CardBody>
            <textarea
              ref={this.textarea}
              value={`http://tripleh.vn/short/${element.name}`}
            />
          </CardBody>
          <CardFooter>
            <Button onClick={this._copy}>Copy</Button>
          </CardFooter>
        </Card>
      </Col>
    )
  }
}

ShortLink.propTypes ={
  element: PropTypes.array.isRequired
}

class ShortURL extends Component {
  render() {
    const {results} = this.props
    return (
      <Col>
        <Row>
          <Col>
            <h2>Link đăng nhập để chạy ứng dụng/ Chỉ sử dụng 1 lần.</h2>
          </Col>
        </Row>
        <Row>
          <Table>
            <thead>
              <tr>
                <th>Ho va ten</th>
                <th>Email</th>
                <th>Chuc Vu</th>
                <th>Don Vi</th>
                <th>Phong Ban</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              
              {isEmpty(results) ? "" : results.map(result => 
                <tr>
                  <th>{result.GiamKhao.FullName}</th>
                  <th>{result.GiamKhao.Email}</th>
                  <th>{result.GiamKhao.ChucVu}</th>
                  <th>{result.GiamKhao.DonVi}</th>
                  <th>{result.GiamKhao.PhongBan}</th>
                  <th>{result.name}</th>
                </tr>
              )}
            </tbody>
          </Table>
          
        </Row>
      </Col>
    )
  }
}
ShortURL.propTypes = {
  results: PropTypes.array.isRequired
}
export default ShortURL