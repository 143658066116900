import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import eventsReducer from './eventsReducer';
import peopleReducer from './peopleReducer'

export default combineReducers({
  auth: authReducer,
  events: eventsReducer,
  peoples:peopleReducer,
  errors: errorReducer
});
