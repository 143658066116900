import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Col,Row,Media,Button,ButtonGroup} from 'reactstrap'
import isEmpty from '../../validation/is-empty';

class ResultShowScreen extends Component {
  constructor(){
    super()
    this.state={
      current:0
    }
    this._buttonChange = this._buttonChange.bind(this)
  }
  _buttonChange=(current)=>{
    this.setState({current})
  }
  render() {
    const {KetQua} = this.props.results
    return (
      <React.Fragment>
        <Row style={{height:'8vh'}} className="pt-2 pb-2" >
          <Col xs={1} xl={3} style={{height:'110%'}} className="d-flex justify-content-md-center" >
            <Media style={{maxWidth:"100%",maxHeight:'100%'}} src={isEmpty(this.props.results.MainLayout.HeaderLeft) ? "" : this.props.results.MainLayout.HeaderLeft}/>
          </Col>
          <Col xs={10} xl={6} style={{height:'100%'}} className="d-flex justify-content-md-center">
            <Media style={{maxWidth:"100%",maxHeight:'100%'}} src={isEmpty(this.props.results.MainLayout.HeaderCenter) ? "" : this.props.results.MainLayout.HeaderCenter}/>
          </Col>
          <Col xs={1} xl={3} >
          </Col>
        </Row>
        <div style={{height:'80vh'}} className=" rowspecial justify-content-md-center align-items-center">
          <Col xs={10} style={{maxHeight:'80vh'}}>
            <Row className="justify-content-md-center" >
              {KetQua[this.state.current].Results.map(result => 
                <Col xs={6} className='mb-3'>
                  <Media style={{backgroundColor:'#fff'}} >
                    <Media left className="mr-2">
                      <Media object src={result.Avatar} alt={result.FullName} style={{width:'30vh'}}/>
                    </Media>
                    <Media body className="align-self-center" >
                      <Media heading style={{width:'100%'}}>{result.FullName}</Media>
                        {result.ChucVu} {result.DonVi}<br/>
                        Số lượt bình chọn: {result.luot}<br/>
                        Tỉ lệ % phiếu bình chọn: {KetQua[this.state.current].GiamKhao}
                    </Media>
                  </Media>
                </Col>
                
                )}
            </Row>
          </Col>
          
        </div>
        <Row>
          <Col className="text-center">
            <ButtonGroup>
              {KetQua.map((kq,index) => 
                <Button className={this.state.current===index ? "btn-blue" : "btn-green"} onClick={()=>this._buttonChange(index)}>{kq.Name}</Button>
                )}
            </ButtonGroup>
            
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
ResultShowScreen.propTypes = {
  results: PropTypes.object.isRequired
};
export default ResultShowScreen