import { 
  SET_EVENTS_LOADING,
  GET_EVENTS,
  SET_CURRENT_EVENTS,
  UPDATE_EVENT,
  SET_DETAIL,
  UPDATE_BAN_GIAM_KHAO,
  UPDATE_NG_DANH_GIA,
  ADD_DANH_GIA,
  UPDATE_NAME_DANH_GIA,
  SET_SHORT_LINK,
  GET_RESULTS,
  ADD_BINH_CHON,
  UPDATE_GROUP_BINH_CHON,
  UPDATE_BINH_CHON,
  SET_XOSO_RANGE,
  IMPORT_DANH_GIA,
  ADD_MORE_PRIZE,
  UPDATE_PRIZE
} from '../actions/types';
import isEmpty from '../validation/is-empty'
const initialState = {
  loading:false,
  events:[],
  event:null,
  results:[],
  detail:{},
  ShortLink:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_EVENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_EVENTS:
      return {
        ...state,
        events:action.payload,
        loading: false
      };
    case SET_CURRENT_EVENTS:
      return {
        ...state,
        event: action.payload,
        loading: false
      };
    case UPDATE_EVENT:
      return {
        ...state,
        event: {
          ...state.event,
          MainLayout:{
            ...state.event.MainLayout,
            [action.payload.Name]: action.payload.Value
          }
        },
        loading:false
      };
    case SET_DETAIL:
      return {
        ...state,
        loading:false,
        detail:action.payload
      }
    case UPDATE_BAN_GIAM_KHAO:
      return {
        ...state,
        loading: false,
        event:{
          ...state.event,
          DanhGia: state.event.DanhGia.map((dg,index)=>{
            if(index === action.payload.current){
              return {
                ...dg,
                GiamKhao:action.payload.data
              }
            } else {
              return{
                ...dg
              }
            }
          })
        }
      }
    case UPDATE_NG_DANH_GIA:
        return {
          ...state,
          loading: false,
          event:{
            ...state.event,
            DanhGia: state.event.DanhGia.map((dg,index)=>{
              if(index === action.payload.current){
                return {
                  ...dg,
                  NgDanhGia:action.payload.data
                }
              } else {
                return{
                  ...dg
                }
              }
            })
          }
          
        }
    case IMPORT_DANH_GIA:
      return{
        ...state,
        event:{
          ...state.event,
          DanhGia: state.event.DanhGia.map((dg,index)=>{
            if(index === action.payload.Current){
              return {
                ...dg,
                [action.payload.Name]: [...dg[action.payload.Name],action.payload.Value]
              }
            } else {
              return{
                ...dg
              }
            }
          })
        }
      }

    case ADD_DANH_GIA:
      return{
        ...state,
        event:{
          ...state.event,
          DanhGia:[...state.event.DanhGia,action.payload]
        }
      }
    case UPDATE_NAME_DANH_GIA:
      return{
        ...state,
        event:{
          ...state.event,
          DanhGia: state.event.DanhGia.map((dg,index)=>{
            if(index === action.payload.current){
              return {
                ...dg,
                Name:action.payload.data
              }
            } else {
              return{
                ...dg
              }
            }
          })
        }
      }
    case ADD_BINH_CHON:
      return{
        ...state,
        event:{
          ...state.event,
          BinhChon:{
            ...state.event.BinhChon,
            Group:[...state.event.BinhChon.Group,action.payload]
          }
        }
      }
    case UPDATE_GROUP_BINH_CHON:
      return{
        ...state,
        event:{
          ...state.event,
          BinhChon:{
            ...state.event.BinhChon,
            Group: state.event.BinhChon.Group.map((groups,index)=>{
              if(index === action.payload.Current){
                return {
                  ...groups,
                  [action.payload.Name]:action.payload.Value
                }
              } else {
                return{
                  ...groups
                }
              }
            })
          }
        }
      }
    case UPDATE_BINH_CHON:
      return{
        ...state,
        event:{
          ...state.event,
          BinhChon:{
            ...state.event.BinhChon,
            [action.payload.Name]:action.payload.Value
          }
        }
        
      }
    case GET_RESULTS:
      return{
        ...state,
        loading: false,
        results:action.payload
      }
    case SET_SHORT_LINK:
      return{
        ...state,
        loading: false,
        ShortLink:action.payload
      }
    case SET_XOSO_RANGE:
      return{
        ...state,
        loading:false,
        event:{
          ...state.event,
          XoSo:{
            ...state.event.XoSo,
            Ranges: action.payload
          }
        }
      }
    case ADD_MORE_PRIZE:
      return{
        ...state,
        loading:false,
        event:{
          ...state.event,
          XoSo:{
            ...state.event.XoSo,
            Prize: isEmpty(state.event.XoSo.Prize) ? ['123'] : [action.payload]
          }
        }
        
      }
    case UPDATE_PRIZE:
      return{
        ...state,
        loading:false,
        event:{
          ...state.event,
          XoSo:{
            ...state.event.XoSo,
            Prize: action.payload
          }
        }
      }
    default:
      return state;
  }
}
