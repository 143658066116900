import React, { Component } from 'react'
import PropTypes from 'prop-types';
import isEmpty from '../../validation/is-empty'
import {Row,Col,Table} from 'reactstrap'

class resultScreen extends Component {
  _header=(e)=>{
    let Content=[];
    for(var i=0;i<e.length;i++){
      Content.push(
        <th>
          {e[0][i]}
        </th>
        )
    }
    return Content
  }
  _body=(e)=>{
    let Content=[];
    for(var i=1;i<e.length ; i++){
      let Current = []
      for(var j=0;j<e[0].length;j++){
        Current.push(
          <th>
            {e[i][j]}
          </th>
          )
      }
      Content.push(
        <tr>
          {Current}
        </tr>
      )
    }
    return Content
  }
  render() {
    const {results} = this.props
    return (
      <Col>
        {isEmpty(results) ? "" : 
          <Row className="mt-5">
            <Col xs={12}>
              <Row>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        {this._header(results)}
                      </tr>
                    </thead>
                    <tbody>
                      {this._body(results)}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      </Col>
      
      
    )
  }
}

resultScreen.propTypes = {
  results: PropTypes.array.isRequired
};

export default resultScreen