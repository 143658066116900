import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Row,Container,Spinner,Col} from 'reactstrap'

import SideBar from './SideBar'
import Screen from './Screen'
import ShortURL from '../layout/ShortURL'
import ResultScreen from './resultScreen'

import {getPeopleAll} from '../../actions/peopleAction'
import {
  getCurrentEventID,
  updateBanGiamKhao,
  updateNgDanhGia,
  AddDanhGia,
  updateName,
  saveEvents,
  getShortLink,
  updateEvent,
  getResults,
  setResetPoint,
  importDanhGia
} from '../../actions/eventActions'
import isEmpty from '../../validation/is-empty';

class index extends Component {
  constructor(){
    super()
    this.state={
      current:0,
      screen:'Giao_Dien_Ng_Dung'
    }
    this._current = this._current.bind(this)
    this._actionScreen = this._actionScreen.bind(this)
  }
  componentDidMount(){
    this.props.getPeopleAll()
    this.props.getCurrentEventID(localStorage.getItem("EventID"));
  }
  _current=(current)=>{
    this.setState({current})
  }
  _updateContent=(data)=>{
    this.props.updateEvent(data)
  }
  _actionScreen=(screen)=>{
    switch (screen) {
      case 'Giao_Dien_Ng_Dung':
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        break;
      case 'Luu_Noi_Dung':
        this.props.saveEvents(this.props.events.event)
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        break;
      case "Lay_Duong_Dan":
          this.props.getShortLink(this.props.events.event._id)
          this.setState({screen:"Lay_Duong_Dan"})
        break;
      case 'Xem_Ket_Qua':
        this.props.getResults(localStorage.getItem("EventID"))
        this.setState({screen:"Xem_Ket_Qua"})
        break;
      case 'Xoa_Ket_Qua':
        this.setState({screen:"Xoa_Ket_Qua"})
        this.props.setResetPoint(this.props.events.event._id)
        break;
      default:
        break;
    }
  }
  importDanhGia = (DanhGia) => {
    this.props.importDanhGia(DanhGia)
  }
  render() {
    const {event,results,ShortLink,loading} = this.props.events
    let Content
    if(isEmpty(event) | loading){
      Content= 
        <Row style={{height:'80vh'}}>
          <Col className="d-flex justify-content-md-center align-items-center">
            <Spinner color="primary" />
          </Col>
        </Row>
      
    } else {
      Content=
        <Row className="flex-xl-nowrap">
          <SideBar 
            event={event} 
            peoples={this.props.peoples.Peoples} 
            updateBanGiamKhao={this.props.updateBanGiamKhao}
            updateNgDanhGia={this.props.updateNgDanhGia}
            AddDanhGia = {this.props.AddDanhGia}
            updateName = {this.props.updateName}
            current = {this.state.current}
            updateCurrent = {this._current}
            actionScreen = {this._actionScreen}
            updateContent = {this._updateContent}
            screen = {this.state.screen}
            importDanhGia = {this.importDanhGia}
          />
          {this.state.screen === 'Giao_Dien_Ng_Dung' ? 
            <Screen 
              event={event}
              current={this.state.current}
            /> : ""
          }
          {this.state.screen === 'Lay_Duong_Dan' ? 
            <ShortURL 
            results={ShortLink}
            /> : ""
          }
          {this.state.screen === 'Xem_Ket_Qua' ? 
            <ResultScreen 
              results={results}
            /> : ""
          }
        </Row>
    }
    return (
      <Container fluid>
        {Content}
      </Container>
      
    )
  }
}

index.propTypes = {
  peoples: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  peoples: state.peoples,
  auth: state.auth,
  events: state.events,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {
    getPeopleAll,
    getCurrentEventID,
    updateBanGiamKhao,
    updateNgDanhGia,
    AddDanhGia,
    updateName,
    saveEvents,
    getShortLink,
    updateEvent,
    getResults,
    setResetPoint,
    importDanhGia
  })(index)