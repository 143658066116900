import React from 'react';
import {Container,Row,Col} from 'reactstrap'

export default () => {
  return (
    <Container fluid className="h-100" style={{
      backgroundImage:`url('http://media.hunghau.holdings/medias/banner.png')`,
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat',
      backgroundPosition:'center'
    }}>
      <Row className="h-100 w-100 justify-content-center align-items-center">
        <Col xs="12" className="text-center">
          <h1 className="text-light">Trân trọng Cảm ơn quý Thầy/ Cô/ Anh/ Chị !</h1>
        </Col>
      </Row>
    </Container>
  );
};

