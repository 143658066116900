import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import { REGISTER,APPHOME } from '../../actions/routes';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form, FormGroup, Input, Button, FormFeedback } from 'reactstrap'

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(APPHOME);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push(APPHOME);
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    console.log(errors)
    return (
      <Container fluid className="vh-100">
        <Row className="h-100 w-100 justify-content-center align-items-center">
          <Col className="text-center">
            <Form className="mx-auto" style={{ "max-width": 400 }}>
              <h2>Đăng nhập</h2>
              <FormGroup>
                <Input
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  invalid={errors.email ? true : false }
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Input
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  invalid={errors.password ? true : false }
                />
                <FormFeedback>{errors.password}</FormFeedback>
              </FormGroup>
              <h6 className="text-right">Chưa có tài khoản: <Link to={REGISTER}>Tạo mới</Link></h6>
              <Button onClick={this.onSubmit}>Đăng nhập</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(Login);
