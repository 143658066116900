export const LOGIN = "/dang-nhap"
export const REGISTER = "/dang-ky"

export const EVENTS = '/events/:token'
export const RESULT = '/ket-qua/:token'

export const LANDING ="/"

export const APPHOME = '/app'
export const APPHOME1 = '/app/123'

export const APPBC = "/app/binh-chon"
export const APPDG = "/app/danh-gia"
export const APPCD = "/app/cham-diem"
export const APPXS = "/app/xo-so"
export const APPHANDLE = "/app/:handle"
export const APPPEOPLE = "/app/thanh-vien"
export const DANHGIA = "/app/danh_gia_tin_nhiem"
export const BINHCHON = '/app/binh_chon'
export const SHORTURL = "/short/:url"
export const KETQUA = '/ket_qua/:url'
export const XOSO = '/app/xo_so'