import axios from './axios';
import {
  SET_PEOPLES_LOADING,
  GET_PEOPLES,
  GET_ERRORS,
  SET_TOGGLE
} from './types'

export const getPeopleAll = () => dispatch => {
  dispatch(setPeopleLoading())
  axios
    .get('/api/peoples/all')
    .then(res => 
      dispatch({
        type:GET_PEOPLES,
        payload: res.data
      }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const deletePerson = (id) => dispatch => {
  dispatch(setPeopleLoading())
  axios
    .post('/api/peoples/delete',id)
    .then(res => dispatch({type:GET_PEOPLES,payload: res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    )
}

export const newPerson =(data) => dispatch => {
  dispatch(setPeopleLoading())
  axios
    .post('/api/peoples/add',data)
    .then(res => dispatch({type:GET_PEOPLES,payload: res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}
export const importPeople = (data) => dispatch => {
  dispatch(setPeopleLoading())
  axios.post('/api/peoples/import',data)
    .then(res =>
      dispatch({
        type:GET_PEOPLES,
        payload: res.data
      }))
}
export const savePerson =(data) => dispatch => {
  dispatch(setPeopleLoading())
  axios
    .post('/api/peoples/save',data)
    .then(res => 
      dispatch({
        type:GET_PEOPLES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
} 

export const setToggle = () => dispatch =>{
  dispatch({
    type:SET_TOGGLE
  })
}

export const setPeopleLoading = () => {
  return{
    type:SET_PEOPLES_LOADING
  }
}