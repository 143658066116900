import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {
  Container,
  Row,Col,Input,
  Button,InputGroup,
  InputGroupAddon,Spinner,
  Modal, ModalHeader, 
  ModalBody, ModalFooter,
  Form, FormGroup, FormFeedback
} from 'reactstrap'
import isEmpty from '../../validation/is-empty';
import Person from './Person'
import {getPeopleAll,newPerson,savePerson,deletePerson,setToggle,importPeople} from '../../actions/peopleAction'
import * as XLSX from 'xlsx';

class index extends Component {
  constructor(){
    super()
    this.state = {
      modal: false,
      PersonID:"",
      FullName:"",
      Email:"",
      DonVi:"",
      PhongBan:"",
      ChucVu:"",
      Avatar:"",
      Profile:""
    };
    this.toggle = this.toggle.bind(this);
    this.onNew = this.onNew.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onButtonClick= this.onButtonClick.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  readFile=(e)=>{
    const f = e.target.files[0]
    const reader = new FileReader();
    reader.onload = (evt) => { 
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type:'binary'});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {header:1}).map(result => {
        console.log(result)
        return{
        FullName:result[1],
        Email: result[2],
        ChucVu:result[3],
        DonVi:result[4],
        PhongBan:result[5],
        Profile:result[6],
        Avatar:result[7]
      }});
      this.props.importPeople(data)
    };
    reader.readAsBinaryString(f);
  }
  onSubmit(e){
    const data = {
      PersonID: this.state.PersonID,
      FullName:this.state.FullName,
      Email:this.state.Email,
      DonVi:this.state.DonVi,
      PhongBan:this.state.PhongBan,
      ChucVu:this.state.ChucVu,
      Avatar:this.state.Avatar,
      Profile:this.state.Profile
    }
    if(isEmpty(this.state.PersonID)){
      console.log(data)
      this.props.newPerson(data)
    }else{
      console.log('hre')
      this.props.savePerson(data)
    }
  }
  onDelete(e){
    this.props.deletePerson({id:this.state.PersonID})
  }
  toggle() {
    this.props.setToggle()
  }
  onNew(){
    this.setState({
      PersonID:"",
      FullName:"",
      Email:"",
      DonVi:"",
      PhongBan:"",
      ChucVu:"",
      Avatar:"",
      Profile:""});
      this.props.setToggle()
  }
  onButtonClick=(id)=>{
    const data = this.props.peoples.Peoples.filter(a => a._id === id)[0]
    console.log(data)
    this.setState({
      PersonID: data._id,
      FullName:data.FullName,
      Email:data.Email,
      DonVi:data.DonVi,
      PhongBan:data.PhongBan,
      ChucVu:data.ChucVu,
      Avatar:data.Avatar,
      Profile:data.Profile
    })
    this.toggle()
  }
  componentWillMount(){
    this.props.getPeopleAll()
  }
  render() {
    const {loading, Peoples,toggle} = this.props.peoples
    const {errors} = this.props
    let dataContent;
    console.log(loading)
    if(loading){
      dataContent = (
        <Spinner color="primary"/>
      )
    } else {
      if(isEmpty(Peoples)){
        dataContent = (
          <h4>Chưa có dữ liệu</h4>
        )
      } else {
        dataContent = Peoples.map(rsl => (
          <Person xs={3} key={rsl._id} data={rsl} onButtonClick={this.onButtonClick} buttonName="Xem"/>
        ))
      }
    }
    
    return (
      <Container fluid>
        <Row className="justify-content-end" style={{height:"4rem"}}>
          <Col xl='3'>
            <InputGroup>
              <Input name="Search" placeholder="Find something..."/>
              <InputGroupAddon addonType="append"><Button>Find</Button></InputGroupAddon>
            </InputGroup>
          </Col>
          <Col xl='3'>
            <Button onClick={this.onNew}>Add New</Button>{" "}
            <Input type="file" onChange={(e)=>this.readFile(e)} />{" "}
            <Button>Export</Button>
          </Col>
        </Row>
        
        <Row className="align-items-center justify-content-center" style={{height:"calc(100vh - 8rem)"}}>
          {dataContent}
        </Row>
        <Modal isOpen={toggle} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Thêm thành viên (Không đăng nhập)</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Input type='text' placeholder="Họ và tên" name="FullName" value={this.state.FullName} onChange={this.onChange}/>
                <FormFeedback>{errors.FullName}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input type='email' placeholder="Email" name="Email" value={this.state.Email} onChange={this.onChange}/>
                <FormFeedback>{errors.Email}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input type='text' placeholder="Chức vụ" name="ChucVu" value={this.state.ChucVu} onChange={this.onChange}/>
                <FormFeedback>{errors.ChucVu}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input type='text' placeholder="Đơn vị" name="DonVi" value={this.state.DonVi} onChange={this.onChange}/>
                <FormFeedback>{errors.DonVi}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input type='text' placeholder="Phòng ban" name="PhongBan" value={this.state.PhongBan} onChange={this.onChange}/>
                <FormFeedback>{errors.PhongBan}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input type='text' placeholder="Hình đại diện https://link Hình" name="Avatar" value={this.state.Avatar} onChange={this.onChange} />
                <FormFeedback>{errors.Avatar}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input type='text' placeholder="Profile https://link Profile" name="Profile" value={this.state.Profile} onChange={this.onChange}/>
                <FormFeedback>{errors.Profile}</FormFeedback>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onSubmit}>Lưu</Button>{' '}
            <Button color="danger" onClick={this.onDelete}>Xóa</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Hủy</Button>
          </ModalFooter>
        </Modal>
      </Container>
    )
  }
}

index.propTypes = {
  deletePerson:PropTypes.func.isRequired,
  setToggle:PropTypes.func.isRequired,
  newPerson: PropTypes.func.isRequired,
  savePerson: PropTypes.func.isRequired,
  getPeopleAll: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  peoples: PropTypes.object.isRequired

};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  peoples: state.peoples
});
export default connect(mapStateToProps,{getPeopleAll,newPerson,savePerson,setToggle,deletePerson,importPeople})(index)