import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Modal,ModalHeader,ModalBody,ModalFooter,Input,Button} from 'reactstrap'

class ImagesModals extends Component {
  constructor(){
    super()
    this.state = {
      modal:""
    }
    this.toggle = this.toggle.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }
  toggle(e){
    this.props.onChange({Name:"modal",Value:!this.props.ModalObject.modal})
  }
  onChange(e){
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(){
    this.props.onSubmit(this.state.modal)
  }
  render() {
    const {ModalObject} = this.props
    return (
      <Modal isOpen={ModalObject.modal} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>Nhập url</ModalHeader>
        <ModalBody>
          <Input placeholder="https://nhap media url" name="modal" value={this.state.modal} onChange={this.onChange}/>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onSubmit}>Xác nhận</Button>{' '}
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ImagesModals.propTypes = {
  onChange:PropTypes.func.isRequired,
  onSubmit:PropTypes.func.isRequired,
  ModalObject: PropTypes.object.isRequired
};

export default ImagesModals