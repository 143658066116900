import React from 'react';
import {Container,Row, Col,Media } from 'reactstrap'
import {Link} from 'react-router-dom'

import {LOGIN} from '../actions/routes'

import background from '../img/background-intro.jpg'
import myH from '../img/myh-logo.png'


function landingPage (){
  return(
    <Container fluid className="h-100" style={{
      backgroundImage:`url(${background})`,
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat',
      backgroundPosition:'center'
    }}>
      <Row className="h-100 w-100 justify-content-center align-items-center">
        <Col className="text-center">
          <Link to={LOGIN}>
            <Media object src={myH}  />
          </Link>
          
        </Col>
      </Row>
    </Container>
  )
  
}

export default landingPage

