import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Media,
  Card, 
  Button,
  ButtonGroup,
  CardImg,
  CardTitle, 
  CardBody,
  CardSubtitle,
  CardText,
  CardFooter
} from 'reactstrap'
import isEmpty from '../../validation/is-empty'

class Screen extends Component {
  render() {
    const {event} = this.props
    return (
      <Col style={{background:`url(${event.MainLayout.background}) no-repeat center center`}} className="bd-sidebar">
        <Row style={{height:'8vh'}}>
          <Col xs={3} style={{height:'110%'}} className="d-flex justify-content-md-center" >
            <Media height="100%" src={isEmpty(event.MainLayout.HeaderLeft) ? "" : event.MainLayout.HeaderLeft}/>
          </Col>
          <Col xs={6} style={{height:'100%'}}>
            <Media height="100%"  src={isEmpty(event.MainLayout.HeaderCenter) ? "" : event.MainLayout.HeaderCenter}/>
          </Col>
          <Col xs={3}>
          </Col>
        </Row>
        
        <Row>
          {isEmpty(event.BinhChon.Group) ? "" :
            event.BinhChon.Group.map(grp =>
              isEmpty(grp.NgBinhChon) ? "" :
              grp.NgBinhChon.map((bc,index)=>
              <Col xs={4} key={index}>
                <Card className="mt-1">
                  <CardImg top width="100%" src={bc.Avatar} alt="Card image cap" />
                  <CardBody>
                    <CardTitle>{bc.FullName}</CardTitle>
                    <CardSubtitle>{bc.Email}</CardSubtitle>
                    <CardText>
                      {bc.DonVi}<br/> 
                      {bc.PhongBan}<br/>
                    </CardText>
                  </CardBody>
                  <CardFooter className="text-center">
                    <ButtonGroup>
                      <Button color="secondary">Bình Chọn</Button>
                    </ButtonGroup>
                  </CardFooter>
                </Card>
              </Col>
            ) 
          )
          }
        </Row>
        
      </Col>
    )
  }
}
Screen.propTypes = {
  event: PropTypes.object.isRequired
};

export default Screen