import React, { Component } from 'react'
import PropTypes from 'prop-types';
import isEmpty from '../../validation/is-empty'

import {
  Row,
  Col,
  Button,
  Media
} from 'reactstrap'

class userScreen extends Component {
  constructor(){
    super()
    this.state={
      current:0,
      logs:[]
    }
    this._CheckSavePoint = this._CheckSavePoint.bind(this)
  }
  _DanhGia = (level,PersonID,DanhGiaID) => {
    let logs = this.state.logs
    const data = {level,PersonID,DanhGiaID}
    if(isEmpty(logs)){
      logs.push(data)
    }else {
      const index = logs.findIndex(log => log.PersonID === PersonID && log.DanhGiaID === DanhGiaID)
      if(index === -1){
        logs.push(data)
      } else {
        logs[index] = data
      }
    }
    this.setState({logs})
  }
  _button = (level,PersonID,DanhGiaID) =>{
    const index = this.state.logs.findIndex(log => log.PersonID === PersonID && log.DanhGiaID === DanhGiaID && log.level === level)
    if(index === -1){
      return false
    } 
      return true
  }
  _CheckSavePoint(){
    const {DanhGia} = this.props.event
    let count = 0
    DanhGia.map((dg) => {
      count+= dg.NgDanhGia.length
      return{
        dg
      }
    })
    if(count === this.state.logs.length){
      this.props.SavePoint(this.state.logs)
    } else {
      alert("Vui lòng hoàn tất việc đánh giá.")
    }
    
  }
  render() {
    const {event} = this.props
    return (
      <Col style={{height:'100vh',width:'100vw',overflow:'hidden'}}>
        <div style={{maxHeight:'90vh'}} className="rowspecial justify-content-md-center align-items-center">
          <Col sm={12} style={{height:'10vh'}} >
            <Row style={{height:'10vh'}} className="pt-2 pb-2">
              <Col xs={1} xl={3} style={{height:'110%'}} className="d-flex justify-content-md-center" >
                <Media style={{maxWidth:"100%",maxHeight:'100%'}} src={isEmpty(event.MainLayout.HeaderLeft) ? "" : event.MainLayout.HeaderLeft}/>
              </Col>
              <Col xs={10} xl={6} style={{height:'100%'}} className="d-flex justify-content-md-center">
                <Media style={{maxWidth:"100%",maxHeight:'100%'}} src={isEmpty(event.MainLayout.HeaderCenter) ? "" : event.MainLayout.HeaderCenter}/>
              </Col>
              <Col xs={1} xl={3}>
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={10} style={{maxHeight:'80vh'}}>
            <Row className="justify-content-md-center">
            {isEmpty(event.DanhGia) ? "" : isEmpty(event.DanhGia[this.state.current].NgDanhGia) ? "" : 
            event.DanhGia[this.state.current].NgDanhGia.map(ngdg => 
            <Col xs={12} xl={6} className="pb-3">
              <Media style={{backgroundColor:'#fff'}} >
                <Media left className="mr-2" href={ngdg.Profile} target="_blank" >
                  <Media object src={ngdg.Avatar} alt={ngdg.FullName} className="media_fixed" />
                </Media>
                <Media body className="align-self-center">
                  <Media heading style={{width:'100%'}}>{ngdg.FullName}</Media>
                    {ngdg.ChucVu} <br/> {ngdg.DonVi}<br/>
                    
                    <Button 
                      color={this._button('Thấp',ngdg._id,event.DanhGia[this.state.current]._id) ? "primary"  : "secondary" }
                      onClick={() => this._DanhGia('Thấp',ngdg._id,event.DanhGia[this.state.current]._id)}>Thấp</Button>
                    <Button 
                      color={this._button('Trung bình',ngdg._id,event.DanhGia[this.state.current]._id) ? "primary"  : "secondary" }
                      onClick={() => this._DanhGia('Trung bình',ngdg._id,event.DanhGia[this.state.current]._id)}
                    >Trung bình</Button>
                    <Button 
                      color={this._button('Cao',ngdg._id,event.DanhGia[this.state.current]._id) ? "primary"  : "secondary" }
                      onClick={() => this._DanhGia('Cao',ngdg._id,event.DanhGia[this.state.current]._id)}
                    >Cao</Button>
                  
                </Media>
              </Media>
            </Col>
            )
            }
          </Row>
        </Col>
        </div>
        <Row style={{height:'10vh'}}>
          <Col className='text-center mt-1'>          
              {isEmpty(event.DanhGia) ? "" : 
                event.DanhGia.map((group,index) => 
                  <Button className={this.state.current===index ? "btn-green" : "btn-blue"} 
                    onClick={()=>this.setState({current:index})}>{group.Name}</Button>
                )
              }
              <Button className="btn-yellow" onClick={this._CheckSavePoint}>Save</Button>
          </Col>
        </Row>
        </Col>
    )
  }
}

userScreen.propTypes = {
  event: PropTypes.object.isRequired
};

export default userScreen