import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {Container,Row, Col,Button } from 'reactstrap'
import {LOGIN,REGISTER,APPHOME} from '../../actions/routes'

class Landing extends Component {
  constructor(){
    super()
    this.Login = this.Login.bind(this)
    this.Register = this.Register.bind(this)
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(APPHOME);
    }
  }
  Login(e){
    e.preventDefault()
    this.props.history.push(LOGIN)
  }
  Register(e){
    e.preventDefault()
    this.props.history.push(REGISTER)
  }
  render() {
    return (
      <Container fluid className="h-100" style={{
        backgroundImage:`url('http://media.hunghau.holdings/medias/banner.png')`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center'
      }}>
        <Row className="h-100 w-100 justify-content-center align-items-center">
          <Col xs="12" className="text-center"></Col>
          <Col xs="12" className="text-center">HungHau Holdings</Col>
          <Col className="text-center">
            <Button color='primary' onClick={this.Login}>
              Đăng Nhập
            </Button> {" "}
            <Button color='secondary'>
              Đăng Ký
            </Button>

          </Col>
          <Col xs="12" className="text-center"></Col>
        </Row>
      </Container>
      
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Landing);
