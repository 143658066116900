import React, { Component } from 'react'

import PropTypes from 'prop-types';
import {
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Row,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import Select from 'react-select'
import * as XLSX from 'xlsx';



class SideBar extends Component {
  constructor() {
    super()
    this.state = {
      modalOpen: false,
      NgDanhGia: [],
      BanGiamKhao: [],
      content: null,
      LogoClick: null,
      Name: null
    }
    this.toggle = this.toggle.bind(this)
    this._updateBanGiamKhao = this._updateBanGiamKhao.bind(this)
    this._updateNgDanhGia = this._updateNgDanhGia.bind(this)
    this._onChange = this._onChange.bind(this)
    this._updateContent = this._updateContent.bind(this)
    this.readFile = this.readFile.bind(this)
  }
  toggle() {
    this.setState({ modalOpen: !this.state.modalOpen })
  }
  _updateBanGiamKhao = (BanGiamKhao, index) => {
    this.props.updateBanGiamKhao(BanGiamKhao, index)
  }
  _updateNgDanhGia = (NgDanhGia, index) => {
    this.props.updateNgDanhGia(NgDanhGia, index)
  }
  _importDanhGia = (DanhGia,Name,Current) => {
    this.props.importDanhGia({Value:DanhGia,Name,Current})
  }
  _onChange(e) {
    this.setState({ content: e.target.value })
  }
  _toggleButton = (LogoClick) => {
    this.setState({ modalOpen: !this.state.modalOpen })
    this.setState({ LogoClick })
  }
  _updateContent(e) {
    this.setState({ modalOpen: !this.state.modalOpen })
    this.props.updateContent({ Name: this.state.LogoClick, Value: this.state.content })
  }
  readFile=(e,name)=>{
    const {peoples,current} = this.props
    const f = e.target.files[0]
    const reader = new FileReader();
    reader.onload = (evt) => { 
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type:'binary'});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {header:1}).map(result => {
        this._importDanhGia(peoples.filter(x => x.Email===result[2].toLowerCase())[0],name,current)
        return {
          result
        }
      });
      //this.props.importDanhGia({Name:"GiamKhao",Value:data)
    };
    reader.readAsBinaryString(f);
  }
  render() {
    const { event, current, peoples,screen } = this.props
    return (
      <Col xs="12" xl="3" md="3" className="bd-sidebar">
        <Nav vertical pills>
          <NavItem>
            <NavLink active={screen==='Giao_Dien_Ng_Dung'} onClick={() => this.props.actionScreen('Giao_Dien_Ng_Dung')}>Giao diện người dùng</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={screen==='Lay_Duong_Dan'} onClick={() => this.props.actionScreen('Lay_Duong_Dan')}>Lấy đường dẫn đăng nhập</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={screen==='Xem_Ket_Qua'} onClick={() => this.props.actionScreen('Xem_Ket_Qua')}>Xem kết quả</NavLink>
          </NavItem>
          
        </Nav>
        <hr />
        <Row >
          <Col className='text-center'>
            <ButtonGroup>
              <Button onClick={() => this._toggleButton('HeaderLeft')}
                className="btn-background"
                style={{ background: `url(${event.MainLayout.HeaderLeft}) no-repeat center center` }}
              >
                Left Logo
              </Button>
                <Button onClick={() => this._toggleButton('HeaderCenter')}
                  className="btn-background"
                  style={{ background: `url(${event.MainLayout.HeaderCenter}) no-repeat center center` }}
                >
                  Center Logo
              </Button>
                <Button onClick={() => this._toggleButton('HeaderRight')}
                  className="btn-background"
                  style={{ background: `url(${event.MainLayout.HeaderRight}) no-repeat center center` }}
                >
                  Right Logo
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Button onClick={() => this._toggleButton('background')} style={{
            height: '12vh',
            background: `url(${event.MainLayout.background})`,
            backgroundSize: "cover",
            textShadow: "-1px 0 #000, 0 1px #000, 1px 0 #000,0 -1px #000"
          }} block>Background</Button>
        </Row>
        <hr />
        <h5 className="text-center">
          Dữ liệu đánh giá tín nhiệm
        </h5>
        {event.DanhGia.length > 0 ?
          <React.Fragment>
            <FormGroup>
              <Label for="name">Tên</Label>
              <Input type="text" name="Name" id="name" placeholder="Tên mục đánh giá"
                value={event.DanhGia[current].Name}
                onChange={(e) => this.props.updateName(e.target.value, current)}
              />
            </FormGroup>
            <span>Danh sách Ban giám khảo</span>
            <Row>
              <Col>
                <Select
                  isMulti
                  options={peoples}
                  getOptionLabel={option => `${option.FullName} \n ${option.Email}`}
                  getOptionValue={option => `${option._id}`}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={event.DanhGia[current].GiamKhao}
                  onChange={(e) => this._updateBanGiamKhao(e, current)}
                />
                <Input type="file" onChange={(e)=>this.readFile(e,"GiamKhao")} />
              </Col>
            </Row>
            <span>Danh sách ngừoi đánh giá</span>
            <Row>
              <Col>
                <Select
                  isMulti
                  options={peoples}
                  getOptionLabel={option => `${option.FullName} \n ${option.Email}`}
                  getOptionValue={option => `${option._id}`}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={event.DanhGia[current].NgDanhGia}
                  onChange={(e) => this._updateNgDanhGia(e, current)}
                />
                <Input type="file" onChange={(e)=>this.readFile(e,"NgDanhGia")} />
              </Col>
            </Row>
          </React.Fragment>
          : ""}

        <br />
        <div className="text-center">
          <ButtonGroup className="text-center" size="sm">
            {event.DanhGia.map((dt, index) =>
              <Button key={index} onClick={() => this.props.updateCurrent(index)}>{index + 1}</Button>
            )}
          </ButtonGroup>
        </div>
        <div className="text-center">
          <ButtonGroup className="text-center">
            <Button color="primary">Xoá</Button>
            <Button color="danger" onClick={() => this.props.AddDanhGia({ Name: '', NgDanhGia: [], GiamKhao: [] })}>Thêm</Button>
          </ButtonGroup>
        </div>

        <br />
        <Modal isOpen={this.state.modalOpen} toggle={this.toggle} backdrop>
          <ModalHeader toggle={this.toggle}>
            Title
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="content">Nhập địa chỉ hình ảnh</Label>
              <Input type="text" id="content" placeholder="Link"
                value={this.state.content}
                onChange={this._onChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this._updateContent}>Save</Button>
            <Button onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Col>
    )
  }
}

SideBar.propTypes = {
  actionScreen: PropTypes.func.isRequired,
  updateNgDanhGia: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  peoples: PropTypes.array.isRequired
};

export default SideBar