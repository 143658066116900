import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {updateEvent,UpdateTokenResult} from '../../actions/eventActions'
import { Row,Col,Media } from 'reactstrap'
import ImagesModals from '../common/ImagesModals'
import Slider from 'rc-slider'
import isEmpty from '../../validation/is-empty'

const Range = Slider.createSliderWithTooltip(Slider.Range);

class Content extends Component {
  constructor(){
    super()
    this.state={
      current:0,
      flag:true, // Flag for Giai thuong
      play:false,
      limit:2,
      begin:0,
      ModalObject:{
        Vitri:0,
        modal:false,
        Value:""
      },
      CurrentAnimation:0,
      randNum:0,
      ScrollNum:0,
      Number:0,
      num:{
        0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0
      },
      Mark:[
        {Mark:'A',Value:0}
      ],
      Rule:[
        {DonVi:'HHO',Mark:'A',Value:0},
        {DonVi:'CCC',Mark:'A',Value:0},
        {DonVi:'HA1',Mark:'A',Value:0},
        {DonVi:'HAF',Mark:'A',Value:0},
        {DonVi:'HAW',Mark:'A',Value:0},
        {DonVi:'HCF',Mark:'A',Value:0},
        {DonVi:'HCM',Mark:'A',Value:0},
        {DonVi:'HDD',Mark:'A',Value:0},
        {DonVi:'HEA',Mark:'A',Value:0},
        {DonVi:'HEX',Mark:'A',Value:0},
        {DonVi:'HEH',Mark:'A',Value:0},
        {DonVi:'HES',Mark:'A',Value:0},
        {DonVi:'HFN',Mark:'A',Value:0},
        {DonVi:'HHD',Mark:'A',Value:0},
        {DonVi:'HHF',Mark:'A',Value:0},
        {DonVi:'HHA',Mark:'A',Value:0},
        {DonVi:'HFV',Mark:'A',Value:0},
        {DonVi:'HET',Mark:'A',Value:0},
        {DonVi:'VHU',Mark:'A',Value:0}
      ]
    }
    this.keypush = this.keypush.bind(this)
    this.onchange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.modalToggle = this.modalToggle.bind(this)
    this.onRangeChange = this.onRangeChange.bind(this)
  }

  
  componentDidMount(){
    document.addEventListener("keydown",this.keypush,false);
    const {event} = this.props.events
    if(!isEmpty(event.EventType)){
      if(event.ResultLot.length >0){
        const role = this.state.Rule.map( result => {
          return{
            ...result,
            Value : event.ResultLot.filter(app => app.Group === result.DonVi).length
          }
        })
        const mark = this.state.Mark.map(kq => {
          let val = role.reduce( (a,b) => {return b.Mark === kq.Mark ? a + b.Value : a},0)
          return {
            ...kq,
            Value:val
          }
        })
        this.setState({
          Rule: role,
          Mark: mark.sort((a,b)=>{
            if(a.Value>b.Value){return 1}
            return -1
          })
        })
      }
      
      
    }
    
  }
  componentWillUnmount(){
    document.removeEventListener("keydown",this.keypush,false);
  }
  onRangeChange = (value,data) =>{
    const dataz = {
      ...this.props.events.event.RangeRandom,
      [value]:data
    } 
    this.props.updateEvent({Name:"RangeRandom",Value:dataz})
  }
  audio = new Audio('http://media.hunghau.holdings/medias/nhac-quay-so.mp3')
  finalAudio = new Audio('http://media.hunghau.holdings/medias/final-clapping.mp3')
  togglePlay = () => {
    this.setState({ play: !this.state.play }, () => {
      if(this.state.play){
        this.audio.play()
      }else{
        this.audio.pause()
        this.finalAudio.play()
      }
    });
  }
  keypush(e){
    const {event} = this.props.events
    switch(e.keyCode){
      // >>>>>>
      case 39:
        if(this.state.current < parseInt(this.props.events.event.amountWin)-1){
          this.setState(pre => ({current:pre.current+1}))
        }else{
          this.setState({current:0})
        }
        break;
      // <<<<<
      case 37:
          if(this.state.current>0){
            this.setState(pre => ({current:pre.current-1}))
          }else{
            this.setState({current:parseInt(this.props.events.event.amountWin)-1})
          }
        break;
      // Down 
      case 40:
        this.setState({flag:false})
        break;
      // Up
      case 38:
        this.setState({flag:true})
        break;
      // spacebar
      case 32:
        switch (event.Type) {
          case 1:
            if(this.state.CurrentAnimation < this.props.events.event.amount){
              if(this.state.Number.toString()===this.state.ScrollNum.toString()){
                if(this.state.CurrentAnimation === 0){
                  let begin=""
                  let end="";
                  for(let i =0;i<this.props.events.event.amount;i++){
                    begin+=this.props.events.event.RangeRandom[i][0]
                    end+=this.props.events.event.RangeRandom[i][1]
                  }
                  const rand = this.runRandom(begin,end)
                  this.setState({randNum:rand})
                }
                this.animateValue(0,parseInt(this.state.randNum.toString()[this.state.CurrentAnimation])+100,2000,this.state.CurrentAnimation)
                this.setState(pre=>({
                  ...pre,
                  Number:parseInt(this.state.randNum.toString()[this.state.CurrentAnimation])+100,
                  num:{
                    ...pre.num,
                    [pre.CurrentAnimation]:pre.num[pre.CurrentAnimation]+1},
                  CurrentAnimation:pre.CurrentAnimation+1
                }))
              }
            } ;
            break;
          case 2:
          case 3:
            
            if(this.state.Number === 0){
            if(this.state.Number.toString()===this.state.ScrollNum.toString()){
              this.togglePlay()
              if(this.state.CurrentAnimation === 0){
                let begin=""
                for(let i =0;i<this.props.events.event.amount;i++){
                  begin+=this.props.events.event.RangeRandom[i][0]
                }
                let rand = this.runRandom(begin, parseInt(begin) + event.Peoples.length-1)
                if(!isEmpty(event.fw) & this.state.current===2 & event.ResultLot.filter(value => value.value === event.fw).length === 0){
                  rand = event.fw
                }
                this.setState({randNum:rand})
                const func = function() {
                  this.setState({begin:parseInt(rand) - parseInt(begin)},()=>this.togglePlay())
                }.bind(this)
                setTimeout(func, 12000);
              }
              this.animateValue(0,parseInt(this.state.randNum.toString()[0])+100,2000,0)
              this.animateValue(0,parseInt(this.state.randNum.toString()[1])+200,4000,1)
              this.animateValue(0,parseInt(this.state.randNum.toString()[2])+300,6000,2)
              this.animateValue(0,parseInt(this.state.randNum.toString()[3])+400,8000,3)
              
              this.setState(pre=>({
                ...pre,
                Number:this.state.randNum.toString()
              }))
            }
            }

            break;
          default:
            break;
        }
        
        break;
      // enter
      case 13:
        if(e.shiftKey){
          let result = this.props.events.event.ResultLot
          result.push({value:this.state.randNum,No:this.state.current})
          this.props.UpdateTokenResult({
            token:this.props.match.params.token,
            Result:{value:this.state.randNum,No:this.state.current,Group:event.Peoples[this.state.begin].DonVi}
          })
          if(event.ResultLot.length >0){
            const role = this.state.Rule.map( result => {
              return{
                ...result,
                Value : event.ResultLot.filter(app => app.Group === result.DonVi).length
              }
            })
            const mark = this.state.Mark.map(kq => {
              let val = role.reduce( (a,b) => {return b.Mark === kq.Mark ? a + b.Value : a},0)
              return {
                ...kq,
                Value:val
              }
            })
            this.setState({
              Rule: role,
              Mark: mark.sort((a,b)=>{
                if(a.Value>b.Value){return 1}
                return -1
              })
            })
          }
          this.setState(pre=>({
            ...pre,
            CurrentAnimation:0,
            Number:0,
            begin:0,
            ScrollNum:0,
            num:{
              0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0
            }
          }));
        }
        break;
      default: break;
    }
  }
  runRandom=(begin,end)=>{
    let range = parseInt(end) - parseInt(begin)
    let rand = Math.floor(Math.random() * range) + 1;
    if(this.props.events.event.ResultLot.filter(value => value.value === rand+parseInt(begin)).length === 0){
      if(this.state.Rule.filter(vaz => vaz.DonVi === this.props.events.event.Peoples[parseInt(rand)].DonVi)[0].Mark === this.state.Mark[0].Mark){
        return (parseInt(rand) + parseInt(begin))
      }
      return this.runRandom(begin,end)
    }
    return this.runRandom(begin,end)
  }
  onChange = (e) => {
    this.setState(pre => ({
      ...pre,
      ModalObject:{
        ...pre.ModalObject,
        [e.Name]:e.Value
      }
    }))
  }
  onSubmit = (e) => {
    this.setState(pre => ({
      ...pre,
      ModalObject:{
        ...pre.ModalObject,
        modal:!pre.ModalObject.modal,
      }
    }))
    let data = this.props.events.event.WinImages
    data[this.state.ModalObject.Vitri] = e
    this.props.updateEvent({Name:"WinImages",Value:data})
  }
  modalToggle = (e) => {
    this.setState(pre => ({
      ...pre,
      ModalObject:{
        ...pre.ModalObject,
        Vitri:e,
        modal:!pre.ModalObject.modal
      }
    }))
    
  }
  animateValue(start,end, duration,ind) {
    let range = end - start;
    let current = start;
    let increment = end > start? 1 : -1;
    const step = function() {
      current += increment;
      this.setState(pre => ({
        ...pre,
        ScrollNum:current,
        num:{
          ...pre.num,
          [ind]:current.toString().split('').pop()
        }
      }))
      if (current !== end) {
        setTimeout(step, ((duration * 4) / Math.pow(range, 3)) * Math.pow(current, 2));
      }
    }.bind(this);
    setTimeout(step, ((duration * 4) / Math.pow(range, 3)) * Math.pow(start, 2));
  }
  render() {
    const { event } = this.props.events
    let arrayData 
    if(this.state.flag) {
      arrayData=(
        <Row style={{height:'60vh'}}>
          <Col className="d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
            <Media className="text-center" src={event.WinImages[this.state.current]} onClick={()=>this.modalToggle(this.state.current)} object style={{height:"70%"}} alt={"Giai "+this.state.current} />
          </Col>
        </Row>
      )
    } else {
      let content =[]
      for(let i = 0;i<parseInt(event.amount);i++){
        content.push(
          <Col className="d-flex flex-column justify-content-center align-items-center" 
            style={parseInt(event.amount) !== 1 ? 
              {background:`url(${event.amountBackground}) no-repeat center center`,backgroundSize:'90% auto'} : 
              {background:`url(${event.amountBackground}) no-repeat center center`,backgroundSize:'auto 90%'}
            }>
            <h1 className="text-center" style={event.Type === 3 ? {color:event.FontColor,fontSize:'5rem'}: {color:event.FontColor,fontSize:'3.5rem'}}>{this.state.num[i]}</h1>
            {this.props.edit ? (
              <Range 
                allowCross={false} 
                min={0} 
                max={10} 
                value={event.RangeRandom[i]} 
                onChange={(value)=>this.onRangeChange(i,value)} 
                tipFormatter={value => `${value}`} />) 
            : ""}
            
          </Col>
          
          )
      }
      arrayData=(
        <Row className="align-items-center justify-content-center" style={{height:'70vh'}}>
          <Col xs="10">
            <Row >{
              event.Type===3?(
                <Col xs='5' className="d-flex justify-content-center align-items-center" style={{height:'80vh'}}>
                  <Media className="text-center" src={event.WinImages[this.state.current]} onClick={()=>this.modalToggle(this.state.current)} object style={{height:"70%"}} alt={"Giai "+this.state.current} />
                </Col>
              ) : null}
              
              <Col className="align-items-center justify-content-center" style={{height:'80vh'}}>
                <Row style={{height:'5vh'}}></Row>
                <Row style={{height:'40vh'}}>
                  {event.Type===2?(<Col xs="3"></Col>):null}
                  {content.map(result => result)}
                  {event.Type===2?(<Col xs="3"></Col>):null}
                </Row>
                {isEmpty(event.Peoples) | event.Type === 1 ? null : (
                  <Row style={{height:'35vh'}}>
                    <Col xs='12' >
                      <Row className="align-items-center justify-content-center"> 
                        <h4 style={{color:'#81c041'}}> XIN CHÚC MỪNG SINH VIÊN </h4> 
                      </Row>
                      <Row className="align-items-center justify-content-center"> 
                        <h2 className="text-light" style={{fontWeight:'bold'}}> {event.Peoples[this.state.begin].Ten} - {event.Peoples[this.state.begin].DonVi} </h2> 
                      </Row>
                      <Row className="align-items-center justify-content-center"> 
                        <h4 className="text-light" style={{FontColor:'#ccc'}}>{event.Peoples[this.state.begin].Email} </h4>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
              
            </Row>
            
          </Col>
        </Row>
      )
    }
    return (
      <Col xs="12" xl={this.props.edit?"9":"12"} md={this.props.edit?"9":"12"}
        style={this.props.edit ? 
          {background:`url(${event.background})`,backgroundSize:"100% 100%"} :
          {background:`url(${event.background}) no-repeat center center`,backgroundSize:"100% auto"}}
      >
        <Row style={{paddingTop:'10vh'}}>
          <Col xs="2" style={{height:'10vh'}} className="text-right">
            <Media style={{height:'120%'}} object src={event.HeaderLeft} alt="Left Logo" />
          </Col>
          <Col xs="8" className="text-center" style={{height:'8vh'}}> 
            <Media style={{height:'100%'}} object src={event.HeaderCenter} alt="Center Logo" />
          </Col>
          <Col xs="2" style={{height:'10vh'}} className="text-left">
            <Media style={{height:'120%'}} object src={event.HeaderRight} alt="Right Logo" />
          </Col>
        </Row>
          {arrayData}
          
          {this.props.edit ? (
            <ImagesModals ModalObject={this.state.ModalObject} onChange={this.onChange} onSubmit={this.onSubmit}/>
          ) : null}
        
      </Col>
    )
  }
}
Content.propTypes = {
  edit: PropTypes.object.isRequired,
  updateEvent:PropTypes.func.isRequired,
  UpdateTokenResult:PropTypes.func.isRequired,
  events: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  events: state.events
})
export default withRouter(connect(mapStateToProps,{updateEvent,UpdateTokenResult})(Content))