import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {Container,Col,Row,Spinner} from 'reactstrap'
import isEmpty from '../../validation/is-empty'
import {
  getCurrentEventID,
  getResults,
  saveEvents,
  getShortLink,
  setResetPoint,
  setUpdateRangeXoSo,
  updateEvent,
  addMorePrizes,
  updatePrize,
  importXoSoPeople
} from '../../actions/eventActions'

import SideBar from './SideBar'
import Screen from './Screen'
import ShortURL from '../layout/ShortURL'
import ResultScreen from './resultScreen'

class index extends Component {
  constructor(){
    super()
    this.state={
      screen:"Giao_Dien_Ng_Dung",
      current:0,
      currentPrize:0
    }
    this._updateRangeXoSo = this._updateRangeXoSo.bind(this)
    this._onRangeChange = this._onRangeChange.bind(this)
    this._addMorePrize = this._addMorePrize.bind(this)
  }
  componentDidMount(){
    this.props.getCurrentEventID(localStorage.getItem("EventID"));
  }
  _updateRangeXoSo=(value)=>{
    let Range = this.props.events.event.XoSo.Ranges
    if(Range.length === 0){
      Range = [value]
    }else if(Range.length > value){
      for (let index = value; index < Range.length; index++) {
        Range.splice(-1,1)
      }
    } else if(Range.length<value){
      for (let index = value; index > Range.length; index--) {
        Range.push([0,9])
      }
    }
    this.props.setUpdateRangeXoSo(Range)
  }
  _updatePrize=(data)=>{
    this.props.updatePrize(data)
  }
  _actionScreen=(screen)=>{
    switch (screen) {
      case 'Giao_Dien_Ng_Dung':
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        break;
      case 'Luu_Noi_Dung':
        this.props.saveEvents(this.props.events.event)
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        break;
      case "Lay_Duong_Dan":
          this.setState({screen:"Lay_Duong_Dan"})
          this.props.getShortLink(this.props.events.event._id)
        break;
      case 'Xem_Ket_Qua':
          this.props.getResults(localStorage.getItem("EventID"))
          this.setState({screen:"Xem_Ket_Qua"})
        break;
      case 'Xoa_Ket_Qua':
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        this.props.setResetPoint(this.props.events.event._id)
        break;
      default:
        break;
    }
  }
  _updateContent=(data)=>{
    this.props.updateEvent(data)
  }
  _onRangeChange=(i,data)=>{
    let Range = this.props.events.event.XoSo.Ranges
    Range[i]=data
    this.props.setUpdateRangeXoSo(Range)
  }
  _changeCurrent=(current)=>{
    this.setState({current})
  }
  _addMorePrize(){
    alert('123')
    this.props.addMorePrizes()
    this.setState({currentPrize:this.state.currentPrize+1})
  }
  _importXoSoPeople=(data)=>{
    this.props.importXoSoPeople(data)
  }
  render() {
    const {event,results,ShortLink,loading} = this.props.events
    let Content
    if(isEmpty(event) | loading){
      Content= 
        <Row style={{height:'80vh'}}>
          <Col className="d-flex justify-content-md-center align-items-center">
            <Spinner color="primary" />
          </Col>
        </Row>
      
    } else {
      Content=
          <Row className="flex-xl-nowrap">
            <SideBar
              event={event}
              current={this.state.current}
              changeCurrent = {this._changeCurrent}
              actionScreen = {this._actionScreen}
              updateRangeXoSo = {this._updateRangeXoSo}
              updateContent = {this._updateContent}
              addMorePrize = {this._addMorePrize}
              updatePrize = {this._updatePrize}
              importXoSoPeople = {this._importXoSoPeople}
            />
            {this.state.screen === 'Giao_Dien_Ng_Dung' ? 
              <Screen 
                event={event}
                current={this.state.current}
                onRangeChange = {this._onRangeChange}
              /> : ""
            }
            {this.state.screen === 'Lay_Duong_Dan' ? 
              <ShortURL 
                results={ShortLink}
              /> : ""
            }
            {this.state.screen === 'Xem_Ket_Qua' ? 
              <ResultScreen 
                results={results}
              /> : ""
            }
          </Row>
    }
    return (
      <Container fluid>
        {Content}
      </Container>
    )
  }
}
index.propTypes = {
  auth: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  events: state.events
});
export default connect(mapStateToProps,{
  getCurrentEventID,
  getResults,
  saveEvents,
  getShortLink,
  setResetPoint,
  setUpdateRangeXoSo,
  updateEvent,
  addMorePrizes,
  updatePrize,
  importXoSoPeople
})(index)