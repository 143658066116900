import axios from './axios';

import { 
  SET_EVENTS_LOADING,
  GET_EVENTS,
  GET_ERRORS,
  SET_CURRENT_EVENTS,
  UPDATE_EVENT ,
  SET_DETAIL,
  SET_CURRENT_USER,
  UPDATE_NG_DANH_GIA,
  UPDATE_BAN_GIAM_KHAO,
  ADD_DANH_GIA,
  UPDATE_NAME_DANH_GIA,
  SET_SHORT_LINK,
  GET_RESULTS,
  ADD_BINH_CHON,
  UPDATE_GROUP_BINH_CHON,
  UPDATE_BINH_CHON,
  SET_XOSO_RANGE,
  IMPORT_DANH_GIA,
  ADD_MORE_PRIZE,
  UPDATE_PRIZE
} from './types';

export const getEvents = () => dispatch=>{
  dispatch(getEventLoading())
  axios.get('/api/events/all')
    .then(res => dispatch({
      type : GET_EVENTS,
      payload: res.data
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}
export const getCurrentEvents = (data,history) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/current',data)
    .then(res => {
      dispatch({type:SET_CURRENT_EVENTS,payload:res.data})
      history.push(`/app/${res.data.EventType.toLowerCase()}`)
    })
    
}

export const getCurrentEventID = (id) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/current',{id})
    .then(res => 
      dispatch({type:SET_CURRENT_EVENTS,payload:res.data})
    )
    
}
export const importXoSoPeople = (data) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/importxosopeople',data)
  .then(res=>dispatch({
    type:SET_CURRENT_EVENTS,
    payload:res.data
  }))
}

export const importDanhGia = (data) => dispatch => {
  dispatch({
    type:IMPORT_DANH_GIA,
    payload:data
  })
}
export const addMorePrizes = () => dispatch => {
  dispatch({
    type:ADD_MORE_PRIZE,
    payload:""
  })
}
export const updatePrize = (data) => dispatch => {
  dispatch({
    type:UPDATE_PRIZE,
    payload:data
  })
}

export const getNewCurrent = (data) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/add',data)
    .then(res => dispatch({
      type:SET_CURRENT_EVENTS,
      payload:res.data
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}

export const addGiamKhao = (data) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/giamkhao/add',data)
    .then(res => dispatch({type:SET_CURRENT_EVENTS,payload:res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}

export const removeGiamKhao = (data) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/giamkhao/remove',data)
    .then(res => dispatch({type:SET_CURRENT_EVENTS,payload:res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}

export const getDetail = (data) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/export',data)
    .then(res => dispatch({type:SET_DETAIL,payload:res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}

export const getEventToken = (data,history) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/eventtoken',data)
  .then(res => {
    dispatch({type:SET_CURRENT_EVENTS,payload:res.data.data})
    dispatch({type:SET_CURRENT_USER,payload:res.data.GiamKhao})
  })
  .catch(err =>
    history.push('/WellCome')
  );
}

export const getresultsToken = (data) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/resultsToken',data)
  .then(res => 
    dispatch({type:SET_CURRENT_EVENTS,payload:res.data.data})
  )
}

export const getTokenSubmit = (data,history) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/eventsubmit',data)
  .then(res => {
    if(res.data.Success){
      history.push('/WellCome')
    }
  })
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err.response
    })
  );
}

export const createNew = (data,history) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/create',data)
  .then(res => {
    dispatch({type:SET_CURRENT_EVENTS,payload:res.data})
    history.push((`/app/${(res.data.EventType)}`).toLowerCase())
  })
}

export const resetResult = (id) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/reset',id)
    .then(res => dispatch({type:SET_CURRENT_EVENTS,payload:res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}

export const setUpdateRangeXoSo = (data) =>dispatch => {
  dispatch({
    type:SET_XOSO_RANGE,
    payload: data
  })
}

export const UpdateTokenResult = (data) => dispatch => {
  axios.post('/api/events/updateResultToken',data)
    .then(res => dispatch({type:SET_CURRENT_EVENTS,payload:res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}

export const saveEvents = (data) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/save',data)
    .then(res => dispatch({type:SET_CURRENT_EVENTS,payload:res.data}))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
}

export const updateEvent = (data) => dispatch => {
  dispatch({
    type:UPDATE_EVENT,
    payload:data
  })
}
export const getEventLoading = () => {
  return{
    type:SET_EVENTS_LOADING
  }
}
export const updateGroupBinhChon = (data) => dispatch => {
  dispatch({
    type:UPDATE_GROUP_BINH_CHON,
    payload:data
  })
}

export const getResults = (id) => dispatch => {
  axios.post('/api/events/results',{id})
  .then(result => dispatch({
    type:GET_RESULTS,
    payload: result.data
  }))
}

export const getKetQua = (id) => dispatch => {
  axios.post('/api/events/ketqua',{id})
  .then(result => dispatch({
    type:GET_RESULTS,
    payload: result.data
  }))
}

export const updateBanGiamKhao = (data,current) =>dispatch => {
  dispatch({
    type:UPDATE_BAN_GIAM_KHAO,
    payload:{data,current}
  })
}
export const updateNgDanhGia = (data,current) => dispatch=> {
  dispatch({
    type:UPDATE_NG_DANH_GIA,
    payload:{data,current}
  })
}
export const AddDanhGia = (data) => dispatch => {
  dispatch({
    type:ADD_DANH_GIA,
    payload:data
  })
}
export const updateName = (data,current) => dispatch => {
  dispatch({
    type:UPDATE_NAME_DANH_GIA,
    payload:{data,current}
  })
}
export const getShortLink = (id) => dispatch => {
  axios.post('/api/events/shortlink',{id}).then(result=>
    dispatch({
      type:SET_SHORT_LINK,
      payload:result.data
    })
  )
}

export const setResetPoint = (id) => dispatch => {
  dispatch(getEventLoading())
  axios.post('/api/events/resetpoint',{id})
    .then(result => 
      dispatch({
        type:SET_CURRENT_EVENTS,
        payload:result.data
      }))
}

export const getCurrentUserEvent = (url) => dispatch => {
  axios.post('/api/events/short',{url})
  .then(result =>
    dispatch({
      type:SET_CURRENT_EVENTS,
      payload:result.data
    }))
  .catch(err => 
    dispatch({
      type: GET_ERRORS,
      payload: err.response
    }))
}

export const SavePoint = (data,history) => dispatch => {
  axios.post('/api/events/savepoint',data)
    .then(result => history.push('/thanks-you'))
    .catch(err => 
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      }))
}

export const saveXoSo = (data) =>dispatch=>{
  axios.post('/api/events/savepoint',data)
    .then(result => dispatch({
      type:SET_CURRENT_EVENTS,
      payload:result.data
    }))
    .catch(err => 
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      }))
}

export const AddBinhChon = (data) => dispatch => {
  dispatch({
    type:ADD_BINH_CHON,
    payload:data
  })
}
export const updateBinhChonGK = (data) => dispatch=>{
  dispatch({
    type:UPDATE_BINH_CHON,
    payload:data
  })
}