import React, { Component } from 'react'

import PropTypes from 'prop-types';
import {
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Row,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput
} from 'reactstrap'
import Select from 'react-select'
import isEmpty from '../../validation/is-empty';


class SideBar extends Component {
  constructor() {
    super()
    this.state = {
      modalOpen: false,
      content: null,
      LogoClick: null,
      Name: null
    }
    this.toggle = this.toggle.bind(this)
    this._onChange = this._onChange.bind(this)
    this._updateContent = this._updateContent.bind(this)
  }
  toggle() {
    this.setState({ modalOpen: !this.state.modalOpen })
  }
  _onChange(e) {
    this.setState({ content: e.target.value })
  }
  _toggleButton = (LogoClick) => {
    this.setState({ modalOpen: !this.state.modalOpen, LogoClick})
  }
  _updateContent(e) {
    this.setState({ modalOpen: !this.state.modalOpen })
    this.props.updateContent({ Name: this.state.LogoClick, Value: this.state.content })
  }
  render() {
    const { event, current, peoples } = this.props
    return (
      <Col xs="12" xl="3" md="3" className="bd-sidebar">
        <Nav vertical>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Giao_Dien_Ng_Dung')}>Giao diện người dùng</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Lay_Duong_Dan')}>Lấy đường dẫn đăng nhập</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Xem_Ket_Qua')}>Xem kết quả</NavLink>
          </NavItem>
        </Nav>
        <hr />
        <Row >
          <Col className='text-center'>
            <ButtonGroup>
              <Button onClick={() => this._toggleButton('HeaderLeft')}
                className="btn-background"
                style={{ background: `url(${event.MainLayout.HeaderLeft})`,backgroundSize: "cover" }}
              >
                Left Logo
              </Button>
                <Button onClick={() => this._toggleButton('HeaderCenter')}
                  className="btn-background"
                  style={{ background: `url(${event.MainLayout.HeaderCenter})`,backgroundSize: "cover" }}
                >
                  Center Logo
              </Button>
                <Button onClick={() => this._toggleButton('HeaderRight')}
                  className="btn-background"
                  style={{ background: `url(${event.MainLayout.HeaderRight})`,backgroundSize: "cover" }}
                >
                  Right Logo
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Button onClick={() => this._toggleButton('background')} style={{
            height: '12vh',
            background: `url(${event.MainLayout.background})`,
            backgroundSize: "cover",
            textShadow: "-1px 0 #000, 0 1px #000, 1px 0 #000,0 -1px #000"
          }} block>Background</Button>
        </Row>
        <hr />
        <h5 className="text-center">
          Dữ liệu bình chọn
        </h5>
        <FormGroup>
          <Label>Tên chương trình</Label>
          <Input type="text" placeholder="Tên chương trình"
            value={event.BinhChon.Name}
            onChange={(e) => this.props.updateBinhChonGK({Name:"Name",Value:e.target.value})}
          />
        </FormGroup>
        <FormGroup>
          <Label for="name">Ban Giám Khảo</Label>
          <Select
            isMulti
            options={peoples}
            getOptionLabel={option => `${option.FullName} \n ${option.Email}`}
            getOptionValue={option => `${option._id}`}
            className="basic-multi-select"
            classNamePrefix="select"
            value={event.BinhChon.GiamKhao}
            onChange={(e) => this.props.updateBinhChonGK({Name:"GiamKhao",Value:e})}
          />
        </FormGroup>
        
        <h5 className="text-center">
          Nhóm
        </h5>
        {event.BinhChon.Group.length > 0 ?
          <React.Fragment>
            <FormGroup>
              <Label for="name">Tên group</Label>
              <Input type="text" name="Name" id="name" placeholder="Tên mục đánh giá"
                value={event.BinhChon.Group[current].Name}
                onChange={(e) => this.props.updateBinhChon("Name",e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Tên người đc bình chọn</Label>
              <Select
                isMulti
                options={peoples}
                getOptionLabel={option => `${option.FullName} \n ${option.Email}`}
                getOptionValue={option => `${option._id}`}
                className="basic-multi-select"
                classNamePrefix="select"
                value={event.BinhChon.Group[current].NgBinhChon}
                onChange={(e) => this.props.updateBinhChon("NgBinhChon",e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleCustomRange">Số lượt bình chọn: {event.BinhChon.Group[current].Rule}</Label>
              <CustomInput 
                id="amountWin" 
                type="range" 
                min={0} 
                max={isEmpty(event.BinhChon.Group[current].NgBinhChon) ? 0 :event.BinhChon.Group[current].NgBinhChon.length} 
                value={event.BinhChon.Group[current].Rule} 
                name="amountWin" 
                onChange={(e) => this.props.updateBinhChon("Rule",e.target.value)}/>
            </FormGroup>
          </React.Fragment>
          : ""}

        <br />
        <div className="text-center">
          <ButtonGroup className="text-center" size="sm">
            {event.BinhChon.Group.map((dt, index) =>
              <Button key={index} onClick={() => this.props.updateCurrent(index)}>{index + 1}</Button>
            )}
          </ButtonGroup>
        </div>
        <div className="text-center">
          <ButtonGroup className="text-center">
            <Button color="primary">Xoá</Button>
            <Button color="danger" onClick={() => this.props.AddBinhChon({ Name: '', NgBinhChon: [], Rule: 0 })}>Thêm</Button>
          </ButtonGroup>
        </div>

        <br />
        <Modal isOpen={this.state.modalOpen} toggle={this.toggle} backdrop>
          <ModalHeader toggle={this.toggle}>
            Title
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="content">Nhập địa chỉ hình ảnh</Label>
              <Input type="text" id="content" placeholder="Link"
                value={this.state.content}
                onChange={this._onChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this._updateContent}>Save</Button>
            <Button onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Col>
    )
  }
}

SideBar.propTypes = {
  actionScreen: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  peoples: PropTypes.array.isRequired
};

export default SideBar