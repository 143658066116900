import React, { Component } from 'react'
import PropTypes from 'prop-types';
import isEmpty from '../../validation/is-empty'
import {Row,Col,Table} from 'reactstrap'

class resultScreen extends Component {
  _header=(e)=>{
    let Content=[];
    for(var j=0;j<e[0].length;j++){
      Content.push(
        <th>
          {e[0][j]}
        </th>
        )
    }
    return Content
  }
  _body=(e)=>{
    let Content=[];
    for(var i=1;i<e.length ; i++){
      let Current = []
      for(var j=0;j<e[0].length;j++){
        Current.push(
          <th>
            {e[i][j]}
          </th>
          )
      }
      Content.push(
        <tr>
          {Current}
        </tr>
      )
    }
    return Content
  }
  render() {
    const {results} = this.props
    return (
      <Col>
        {isEmpty(results) ? "" : 
          results.map(result => 
            <Row className="mt-5">
              <Col xs={12}>
                <Row>
                  <Col>
                    <h2 className="text-center">
                      {result.Name}
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          {this._header(result.Result)}
                        </tr>
                      </thead>
                      <tbody>
                        {this._body(result.Result)}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
            )
        }
      </Col>
      
      
    )
  }
}

resultScreen.propTypes = {
  results: PropTypes.array.isRequired
};

export default resultScreen