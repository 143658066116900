import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {updateEvent,getTokenSubmit} from '../../actions/eventActions'
import { Button,Card,CardBody,CardTitle,CardSubtitle,CardText,Input,FormGroup,Label,Row,Col,Media } from 'reactstrap'
import ImagesModals from '../common/ImagesModals'
import isEmpty from '../../validation/is-empty'

class Content extends Component {
  constructor(){
    super()
    this.state={
      current:0,
      flag:true, // Flag for Giai thuong
      limit:2,
      ModalObject:{
        Vitri:0,
        modal:false,
        Value:""
      },
      check:[
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false]
      ],
      Point:[0,0,0,0,0,0,0,0,0,0],
      CurrentAnimation:0,
      randNum:0,
      ScrollNum:0,
      num:{
        0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0
      }
    }
    this.onXacNhan = this.onXacNhan.bind(this)
    this.onCheckChange = this.onCheckChange.bind(this)
    this.onchange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.modalToggle = this.modalToggle.bind(this)
    this.ondataChange = this.ondataChange.bind(this)
  }
  onCheckChange = (i,j,Value) =>{
    let check = this.state.check
    check[i][j] = !this.state.check[i][j]
    let Point = this.state.Point
    if(check[i][j]){
      Point[i] += parseInt(Value)
    } else {
      Point[i] -=Value
    }
    this.setState(pre => ({
      ...pre,
      check,
      Point
    }))
  }
  ondataChange = (val,data) => {
    let dataz = this.props.events.event.data;
    const indx = dataz.findIndex(obj => obj._id === val)
    dataz[indx][data.target.name] = data.target.value
    this.props.updateEvent({Name:"data",Value:dataz})
  }
  onXacNhan(e){
    this.props.getTokenSubmit({Result:this.state.Point,token:this.props.match.params.token},this.props.history)
  }
  onChange = (e) => {
    this.setState(pre => ({
      ...pre,
      ModalObject:{
        ...pre.ModalObject,
        [e.Name]:e.Value
      }
    }))
  }
  onSubmit = (e) => {
    this.setState(pre => ({
      ...pre,
      ModalObject:{
        ...pre.ModalObject,
        modal:!pre.ModalObject.modal,
      }
    }))
    let data = this.props.events.event.WinImages
    data[this.state.ModalObject.Vitri] = e
    this.props.updateEvent({Name:"WinImages",Value:data})
  }
  modalToggle = (e) => {
    this.setState(pre => ({
      ...pre,
      ModalObject:{
        ...pre.ModalObject,
        Vitri:e,
        modal:!pre.ModalObject.modal
      }
    }))
  }
  render() {
    const { loading,event } = this.props.events
    let data=[];
    if(!(loading | isEmpty(event))){
      for(let i=0;i<parseInt(event.amount);i++){
        let cont = []
        for(let j=0;j<parseInt(event.amountWin);j++){
          cont.push(
            <FormGroup check>
              <Input type="checkbox" name="check" id={i+""+j} value={this.state.check[i][j]} onChange={(data) => this.onCheckChange(i,j,event.WinRule[j].Value)}/>
              <Label for={i+""+j} check>{event.WinRule[j].Name} - {event.WinRule[j].Value} điểm</Label> 
            </FormGroup>
          )
        }
        data.push(
          <Col style={{marginBottom:'1rem'}} 
            className="d-flex flex-column justify-content-center align-items-center" 
            xs="12" sm="4">
            <Card>
              <CardBody>
                <CardTitle>
                  {this.props.edit ? (
                    <Input type="text" placeholder="Tên tiết mục..." name="Main" value={event.data[i].Main} 
                      onChange={(data) => this.ondataChange(event.data[i]._id,data)}/>
                  ) : (<h3 className="text-center" style={{color:'#0b6ab2',fontWeight:'bold'}}>{event.data[i].Main}</h3>)}
                  
                </CardTitle>
                <CardSubtitle>
                  {this.props.edit ? (
                      <Input type="text" placeholder="Đơn vị..." name="subMain" value={event.data[i].subMain} 
                        onChange={(data) => this.ondataChange(event.data[i]._id,data)}/>
                    ) : (<h4 className="text-center" style={{color:'#49A942',fontWeight:'bold'}} >{event.data[i].subMain}</h4>) }
                </CardSubtitle>
                <h3 className="text-center">{this.state.Point[i]}</h3>
                <hr/>
                <CardText>
                  {cont}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        )
      }
    }

    return (
      <Col xs="12" xl={this.props.edit?"9":"12"} md={this.props.edit?"9":"12"} 
        style={this.props.edit ? 
          {background:`url(${event.background})`,backgroundSize:"100% 100%"} :
          {backgroundImage:`url(${event.background})`,backgroundRepeat:'no-repeat',backgroundPosition:'center', backgroundSize:'cover'}}
      >
        <Row style={{paddingTop:'10vh'}}>
          <Col xs="2" style={
            this.props.edit?{height:'8vh'}:{
              height:'8vh',
              backgroundImage:`url(${event.HeaderLeft})`,
              backgroundSize:'contain',
              backgroundRepeat:'no-repeat',
              backgroundPosition:'center'
            }} className="text-right">
            {this.props.edit?(<Media style={{width:'100%'}} object src={event.HeaderLeft} alt="Left Logo" />):null}
          </Col>
          <Col xs="8" className="text-center" style={
            this.props.edit?{height:'8vh'}:{
              height:'8vh',
              backgroundImage:`url(${event.HeaderCenter})`,
              backgroundSize:'contain',
              backgroundRepeat:'no-repeat',
              backgroundPosition:'center'
            }}> 
            {this.props.edit?(<Media style={{width:'100%'}} object src={event.HeaderCenter} alt="Center Logo" />):null}
            
          </Col>
          <Col xs="2" style={
            this.props.edit?{height:'8vh'}:{
              height:'8vh',
              backgroundImage:`url(${event.HeaderRight})`,
              backgroundSize:'contain',
              backgroundRepeat:'no-repeat',
              backgroundPosition:'center'
            }} className="text-left">
            {this.props.edit?(<Media style={{width:'100%'}} object src={event.HeaderRight} alt="Right Logo" />):null}
            
          </Col>
        </Row>
        <Row>
          {data}
        </Row>
        {this.props.edit ? null :(
          <Row className="mt-2">
            <Button type='Submit' block color="danger" onClick={this.onXacNhan}>Xác Nhận</Button>
          </Row>
        )}
        {this.props.edit ? null :(
          <ImagesModals ModalObject={this.state.ModalObject} onChange={this.onChange} onSubmit={this.onSubmit}/>
        )}
      </Col>
    )
  }
}
Content.propTypes = {
  edit: PropTypes.object.isRequired,
  getTokenSubmit:PropTypes.object.isRequired,
  updateEvent:PropTypes.func.isRequired,
  events: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  events: state.events
})
export default withRouter(connect(mapStateToProps,{updateEvent,getTokenSubmit})(Content));