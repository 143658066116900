import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Col,Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button} from 'reactstrap'

 class Person extends Component {
  render() {
    const {data,buttonName,xs} = this.props
    return (
      <Col xs={xs}>
        <Card>
          <CardImg top width="100%" src={data.Avatar} alt="Card image cap" />
          <CardBody>
            <CardTitle>{data.FullName}</CardTitle>
            <CardSubtitle>{data.ChucVu}</CardSubtitle>
            <CardText>
              {data.DonVi}<br/> 
              {data.PhongBan}<br/>
              <a href={data.Profile}>Profile</a>
            </CardText>
            <Button block onClick={()=>this.props.onButtonClick(data._id)}>{buttonName}</Button>
          </CardBody>
        </Card>
      </Col>
    )
  }
}
Person.propTypes = {
  onButtonClick:PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};
export default Person