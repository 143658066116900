import React, { Component } from 'react'

import PropTypes from 'prop-types';
import {
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Row,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput
} from 'reactstrap'
import isEmpty from '../../validation/is-empty'
import * as XLSX from 'xlsx';

class SideBar extends Component {
  constructor() {
    super()
    this.state = {
      modalOpen: false,
      content: null,
      LogoClick: null,
      Name: null,
      Prize:0,
    }
    this.toggle = this.toggle.bind(this)
    this._onChange = this._onChange.bind(this)
    this._updateContent = this._updateContent.bind(this)
  }
  toggle() {
    this.setState({ modalOpen: !this.state.modalOpen })
  }
  _togglePrize=(e)=>{
    console.log(e)
    this.setState({modalOpen: !this.state.modalOpen,Prize:e,LogoClick:'None'})
  }
  _onChange(e) {
    this.setState({ content: e.target.value })
  }
  _toggleButton = (LogoClick) => {
    this.setState({ modalOpen: !this.state.modalOpen, LogoClick,content:''})
  }
  _updateContent=(e)=>{
    this.setState({ modalOpen: !this.state.modalOpen })
    if(this.state.LogoClick === 'None'){
      e[this.state.Prize] = this.state.content
      this.props.updatePrize(e)
    }else{
      this.props.updateContent({ Name: this.state.LogoClick, Value: this.state.content })
    }
  }
  _addMorePrize=(e)=>{
    if(isEmpty(e)){
      this.props.updatePrize([""])
    }else{ 
      this.props.updatePrize([...e,""])
    }
  }
  _removePrize=(e)=>{
    if(!isEmpty(e)){
      e.splice(-1,1)
      this.props.updatePrize(e)
    }
  }
  readFile=(e,name)=>{
    const {event} = this.props
    const f = e.target.files[0]
    const reader = new FileReader();
    reader.onload = (evt) => { 
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type:'binary'});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {header:1}).map(result => ({
        Ten:result[1],
        DonVi: result[2],
        Email:result[3]
      }));
      /* Update state */
      
      this.props.importXoSoPeople({id:event._id,data})
    };
    reader.readAsBinaryString(f);
  }
  render() {
    const { event } = this.props
    return (
      <Col xs="12" xl="3" md="3" className="bd-sidebar">
        <Nav vertical>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Giao_Dien_Ng_Dung')}>Giao diện người dùng</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Luu_Noi_Dung')}>Lưu nội dung</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Lay_Duong_Dan')}>Lấy đường dẫn đăng nhập</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Xem_Ket_Qua')}>Xem kết quả</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.props.actionScreen('Xoa_Ket_Qua')}>Xóa kết quả</NavLink>
          </NavItem>
        </Nav>
        <hr />
        <Row >
          <Col className='text-center'>
            <ButtonGroup>
              <Button onClick={() => this._toggleButton('HeaderLeft')}
                className="btn-background"
                style={{ background: `url(${event.MainLayout.HeaderLeft})`,backgroundSize: "cover" }}
              >
                Left Logo
              </Button>
                <Button onClick={() => this._toggleButton('HeaderCenter')}
                  className="btn-background"
                  style={{ background: `url(${event.MainLayout.HeaderCenter})`,backgroundSize: "cover" }}
                >
                  Center Logo
              </Button>
                <Button onClick={() => this._toggleButton('HeaderRight')}
                  className="btn-background"
                  style={{ background: `url(${event.MainLayout.HeaderRight})`,backgroundSize: "cover" }}
                >
                  Right Logo
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Button onClick={() => this._toggleButton('background')} style={{
            height: '12vh',
            background: `url(${event.MainLayout.background})`,
            backgroundSize: "cover",
            textShadow: "-1px 0 #000, 0 1px #000, 1px 0 #000,0 -1px #000"
          }} block>Background</Button>
        </Row>
        <hr />
        <h5 className="text-center">
          Quản trị giao diện
        </h5>
        <FormGroup>
          <Label for="exampleCustomRange">Số lượt bình chọn: {event.XoSo.Ranges.length}</Label>
          <CustomInput 
            id="amountWin" 
            type="range" 
            min={1} 
            max={9}  
            name="amountWin" 
            value={event.XoSo.Ranges.length}
            onChange={(e) => this.props.updateRangeXoSo(e.target.value)}/>
        </FormGroup>
        <h5 className="text-center">
          Cơ cấu giải thưởng 
        </h5>
        {event.XoSo.Prize.length > 0 ? 
          event.XoSo.Prize.map((prize,i) => 
            <Button onClick={() => this._togglePrize(i)}
              className="btn-background"
              style={{ 
                height: '12vh',
                background: `url(${prize})`,
                backgroundSize: "cover",
                textShadow: "-1px 0 #000, 0 1px #000, 1px 0 #000,0 -1px #000" }}
              block
            >
              Giải {i+1}
            </Button>
            )
           : ""}
        <div className="text-center">
          <ButtonGroup className="text-center">
            <Button color="primary" onClick={()=>this._removePrize(event.XoSo.Prize)}>Xoá</Button>
            <Button color="danger" onClick={()=>this._addMorePrize(event.XoSo.Prize)}>Thêm</Button>
          </ButtonGroup>
        </div>
        <h5>Nhập danh sách ng tham dự</h5>
        <Input type="file" onChange={(e)=>this.readFile(e,"NgDanhGia")} />
        
        <br />
        <Modal isOpen={this.state.modalOpen} toggle={this.toggle} backdrop>
          <ModalHeader toggle={this.toggle}>
            Title
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="content">Nhập địa chỉ hình ảnh</Label>
              <Input type="text" id="content" placeholder="Link"
                value={this.state.content}
                onChange={this._onChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={()=>this._updateContent(event.XoSo.Prize)}>Save</Button>
            <Button onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Col>
    )
  }
}

SideBar.propTypes = {
  actionScreen: PropTypes.func.isRequired,
  updateRangeXoSo: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
};

export default SideBar