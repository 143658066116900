import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'

import {getCurrentUserEvent} from '../../actions/eventActions'
import isEmpty from '../../validation/is-empty';
import DanhGiaScreen from '../DanhGia/userScreen'
import BinhChonScreen from '../BinhChon/userScreen'
import XoSoScreen from '../XoSo/userScreen'
import {SavePoint,saveXoSo} from '../../actions/eventActions'
import {Container,Spinner,Row,Col} from 'reactstrap'

class index extends Component {
  constructor(){
    super()
    this._saveXoSo = this._saveXoSo.bind(this)
  }
  componentDidMount(){
    this.props.getCurrentUserEvent(this.props.match.params.url)
  }
  _saveXoSo = (data) =>{
    this.props.saveXoSo(data)
  }
  render() {
    const {event,loading} = this.props.events
    let Content;
    if(!isEmpty(this.props.errors)){
      this.props.history.push('/thanks-you')
    }
    if(!(loading | isEmpty(event))){
      switch (event.EventType) {
        case "DANH_GIA_TIN_NHIEM":
          Content =
            <DanhGiaScreen
              event={event}
              SavePoint = {(data) => this.props.SavePoint({data,url:this.props.match.params.url},this.props.history)}
            />
          break;
        case "BINH_CHON":
          Content =
            <BinhChonScreen
              event={event}
              SavePoint = {(data) => this.props.SavePoint({data,url:this.props.match.params.url},this.props.history)}
            />
          break;
        case "XO_SO":
          Content=
            <XoSoScreen 
              event={event}
              saveXoSo = {(data) => this._saveXoSo({data,url:this.props.match.params.url})}
            />
          break;
        default:
          break;
      }
    } else {
      Content = 
        <Row style={{height:'100vh'}}>
          <Col className="d-flex justify-content-center align-items-center">
            <Spinner/>
          </Col>
        </Row>
      
    }
    return (
      <Container fluid 
        style={{
          height:'100vh',
          width:'100vw',
          backgroundImage:`url(${isEmpty(event) ? "" : event.MainLayout.background})`,
          backgroundSize:"100% 100%",
          backgroundRepeat:'no-repeat'
          }}>
        {Content}
      </Container>
    )
  }
}


index.propTypes = {
  getCurrentUserEvent:PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  events: state.events,
  errors: state.errors
});


export default withRouter(connect(mapStateToProps,{getCurrentUserEvent,SavePoint,saveXoSo})(index))