import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom'
import {getEventToken} from '../../actions/eventActions'
import Content from '../MainContent/Content'
import ContentA from '../MainContent/ContentA'
import isEmpty from '../../validation/is-empty';
import {Spinner,Row,Container} from 'reactstrap'

 class index extends Component {
  componentDidMount(){
    this.props.getEventToken({token:this.props.match.params.token},this.props.history)
  }
  render() {
    const {loading, event} = this.props.events
    let content;
    if(loading|isEmpty(event)){
      content=(
        <Spinner color='primary'/>
      )
    } else {
      switch (event.EventType) {
        case 'xo-so':
          content = (
            <Content edit={false}/>
          )
          break;
        case 'cham-diem':
          content =(
            <ContentA edit={false}/>
          )
          break;
        default:
          break;
      }
    }
    return (
      <Container fluid>
        <Row className="flex-xl-nowrap vh-100 vw-100">
          {content}
        </Row>
      </Container>
      
    )
  }
}


index.propTypes = {
  getEventToken:PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  events: state.events,
  auth:state.auth
});

export default withRouter(connect(mapStateToProps,{getEventToken})(index))