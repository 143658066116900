import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Container} from 'reactstrap'

import BinhChonScreen from '../BinhChon/ResultShowScreen'
import DanhGiaScreen from '../DanhGia/ResultShowScreen'

import {
  getKetQua
} from '../../actions/eventActions'
import isEmpty from '../../validation/is-empty';

class index extends Component {
  componentDidMount() {
    this.props.getKetQua(this.props.match.params.url);
  }
  render() {
    const {results} = this.props.events
    return (
      <Container fluid style={{
        background:`url(${isEmpty(results) ? "" : results.MainLayout.background})`,
        backgroundSize:'cover',
        height:'100vh'
        }}>
        {isEmpty(results) ? "" : 
          results.EventType === "BINH_CHON" ? 
          <BinhChonScreen
            results={results}
          /> : 
          results.EventType === "DANH_GIA_TIN_NHIEM" ? 
          <DanhGiaScreen
            results={results}
          /> : ""
        }
      </Container>
    )
  }
}

index.propTypes = {
  getKetQua:PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  events: state.events,
  errors: state.errors
});

export default withRouter(connect(mapStateToProps,{getKetQua})(index))