import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Media
} from 'reactstrap'
import isEmpty from '../../validation/is-empty'

class userScreen extends Component {
  constructor(){
    super()
    this.state={
      Result:[],
      current:0
    }
    this._BinhChon = this._BinhChon.bind(this)
    this._saveButton = this._saveButton.bind(this)
  }
  _BinhChon=(data)=>{
    
    let results = this.state.Result
    if(results.length === 0){
      results.push(data)
    } else {
      const content = results.filter(value =>value.NgBinhChon === data.NgBinhChon && value.Group === data.Group)
      if(content.length===0){
        results.push(data)
      }else {
        results.splice(results.findIndex(resu => resu.NgBinhChon === data.NgBinhChon && resu.Group === data.Group),1)
      }
    }
    
    this.setState({Result:results})
  }
  _saveButton(){
    const {event} = this.props
    let countBC = 0
    event.BinhChon.Group.map( group => {
      countBC += group.Rule
      return{group}
    })
    if(this.state.Result.length === countBC){
      this.props.SavePoint(this.state.Result)
    } else {
      alert("Vui lòng hoàn tất việc bình chọn.!")
    }
  }
  render() {
    const {event} = this.props
    return (
      <Col style={{height:'100vh',overflow:'hidden'}}>
        <Row style={{height:'8vh'}} className="pt-2 pb-2">
          <Col xs={1} xl={3} style={{height:'110%'}} className="d-flex justify-content-md-center" >
            <Media style={{maxWidth:"100%",maxHeight:'100%'}} src={isEmpty(event.MainLayout.HeaderLeft) ? "" : event.MainLayout.HeaderLeft}/>
          </Col>
          <Col xs={10} xl={6} style={{height:'100%'}} className="d-flex justify-content-md-center">
            <Media style={{maxWidth:"100%",maxHeight:'100%'}} src={isEmpty(event.MainLayout.HeaderCenter) ? "" : event.MainLayout.HeaderCenter}/>
          </Col>
          <Col xs={1} xl={3}>
          </Col>
        </Row>
        <div style={{maxHeight:'80vh'}} className="rowspecial justify-content-md-center align-items-center">
          <Col xs={12} xl={10} style={{maxHeight:'80vh'}}>
            <Row className="justify-content-md-center">
              {isEmpty(event.BinhChon.Group) ? "" :
                event.BinhChon.Group[this.state.current].NgBinhChon.map((bc,index)=>
                  <Col xs={12} xl={6} className='mb-3' key={index} >
                    <Media style={{backgroundColor:'#fff'}} >
                      <Media left className="mr-2"  >
                        <Media object src={bc.Avatar} alt={bc.FullName} style={{minHeight:'10vh',maxHeight:'20vh'}}/>
                      </Media>
                      <Media body className="align-self-center">
                        <Media heading style={{width:'100%'}}>{bc.FullName}</Media>
                          {bc.ChucVu} {bc.DonVi}<br/>
                          <ButtonGroup>
                            <Button className={
                              this.state.Result.filter(resu => resu.NgBinhChon === bc._id && resu.Group === event.BinhChon.Group[this.state.current]._id).length>0 ? "btn-green" :"btn-blue" }
                              disabled={ this.state.Result.filter(resu => resu.NgBinhChon === bc._id && resu.Group === event.BinhChon.Group[this.state.current]._id).length>0 ? 
                                false : this.state.Result.filter(resu => resu.Group === event.BinhChon.Group[this.state.current]._id).length>=event.BinhChon.Group[this.state.current].Rule}
                              onClick={()=>this._BinhChon({NgBinhChon:bc._id,Group:event.BinhChon.Group[this.state.current]._id})}
                            >Bình Chọn</Button>
                          </ButtonGroup>
                      </Media>
                    </Media>
                  </Col>
                ) 
              }
            </Row>
          </Col>
        </div>
        <Row style={{height:'10vh'}}>
          <Col className="text-center pt-2">
            {isEmpty(event.BinhChon.Group) ? "" : 
              event.BinhChon.Group.map((group,index) => 
                <Button className={this.state.current===index ? "btn-green" : "btn-blue"} onClick={()=>this.setState({current:index})}>{group.Name}</Button>
                )
            }
            <Button className="btn-yellow"
            onClick={this._saveButton}>Lưu</Button>
          </Col>
        </Row>
      </Col>
    )
  }
}
userScreen.propTypes = {
  event: PropTypes.object.isRequired
};

export default userScreen