import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Slider from 'rc-slider'
import {Col,Row} from 'reactstrap'
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
const Range = Slider.createSliderWithTooltip(Slider.Range);


class Screen extends Component {
  render() {
    const {event} = this.props
    return (
      <Col style={{background:`url(${event.MainLayout.background}) no-repeat center center`,backgroundSize:'cover'}} className="bd-sidebar">
        <Row>
          {event.XoSo.Ranges.map((range,i) => 
            <Col className="d-flex flex-column justify-content-center align-items-center">
              <h1>0</h1>
              <h2>[{range}]</h2>
              <Range 
                allowCross={false} 
                min={0} 
                max={9} 
                value={range} 
                onChange={(value)=>this.props.onRangeChange(i,value)} 
                tipFormatter={val => `${val}`} 
                tipProps={{
                  placement: 'top',
                  prefixCls: 'rc-slider-tooltip'}}
                />
            </Col>
          )}
        </Row>
      </Col>
    )
  }
}
Screen.propTypes = {
  event: PropTypes.object.isRequired
};
export default Screen