import { 
  SET_PEOPLES_LOADING,
  GET_PEOPLES,
  SET_CURRENT_PEOPLE,
  SET_SELECTED_PEOPLE,
  SET_SELECTED_ONEMORE_PEOPLE,
  SET_TOGGLE
} from '../actions/types';

const initialState = {
  loading: false,
  toggle:false,
  Peoples:[],
  Selected:[],
  People:{}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PEOPLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_TOGGLE:
      return {
        ...state,
        toggle: !state.toggle
      };
      case GET_PEOPLES:
        return {
          ...state,
          loading:false,
          Peoples: action.payload,
          toggle:false
        };
      case SET_CURRENT_PEOPLE:
        return {
          ...state,
          People: action.payload
        };
      case SET_SELECTED_PEOPLE:
        return {
          ...state,
          Selected: action.payload
        };
      case SET_SELECTED_ONEMORE_PEOPLE:
        return {
          ...state,
          Selected: [...action.Selected,action.payload]
        };
    default:
      return state;
  }
}
