export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_EVENTS_LOADING ="SET_EVENTS_LOADING";
export const GET_EVENTS ="GET_EVENTS";
export const SET_CURRENT_EVENTS ="SET_CURRENT_EVENTS";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const SET_DETAIL ="SET_DETAIL";
export const UPDATE_BAN_GIAM_KHAO = "UPDATE_BAN_GIAM_KHAO"
export const UPDATE_NG_DANH_GIA = 'UPDATE_NG_DANH_GIA'
export const ADD_DANH_GIA = 'ADD_DANH_GIA'
export const UPDATE_NAME_DANH_GIA = "UPDATE_NAME_DANH_GIA"
export const SET_SHORT_LINK = "SET_SHORT_LINK"
export const GET_RESULTS = "GET_RESULTS"
export const ADD_BINH_CHON = "ADD_BINH_CHON"
export const UPDATE_GROUP_BINH_CHON = "UPDATE_GROUP_BINH_CHON"
export const UPDATE_BINH_CHON = "UPDATE_BINH_CHON"
export const SET_XOSO_RANGE = "SET_XOSO_RANGE"
export const IMPORT_DANH_GIA = "IMPORT_DANH_GIA"
export const ADD_MORE_PRIZE = "ADD_MORE_PRIZE"
export const UPDATE_PRIZE = "UPDATE_PRIZE"

export const SET_TOGGLE = "SET_TOGGLE";
export const SET_PEOPLES_LOADING = "SET_PEOPLES_LOADING";
export const GET_PEOPLES = "GET_PEOPLES";
export const SET_CURRENT_PEOPLE = "SET_CURRENT_PEOPLE";
export const SET_SELECTED_PEOPLE = "SET_SELECTED_PEOPLE";
export const SET_SELECTED_ONEMORE_PEOPLE = "SET_SELECTED_ONEMORE_PEOPLE";

