import React, { Component } from 'react';
import { NavLink,withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import {createNew} from '../../actions/eventActions'
import {Navbar,
  NavbarBrand,NavbarToggler,
  Collapse,Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
 } from 'reactstrap'
import {
  APPPEOPLE,
  APPHOME
} from '../../actions/routes'

class Dashboard extends Component {
  componentDidMount() {
  }
  constructor(){
    super()
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  } 
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }
  createNewOne = (e) => {
    this.props.createNew({EventType:e},this.props.history)
  }
  render() {
    const { user } = this.props.auth;
    return (
      <Navbar color="light" light expand="md" style={{height:"4rem"}}>
        <NavbarBrand href="/">
          <span style={{color:'#53ac47',fontWeight:'bold'}}>Hung</span>
          <span style={{color:'#0b74b9',fontWeight:'bold'}}>Hau</span>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" to={APPHOME}>Tất cả</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Tạo mới
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={()=>this.createNewOne('BINH_CHON')}>
                  Bình chọn
                </DropdownItem>
                <DropdownItem onClick={()=>this.createNewOne('DANH_GIA_TIN_NHIEM')}>
                  Đánh giá tín nhiệm
                </DropdownItem>
                <DropdownItem onClick={()=>this.createNewOne('CHAM_DIEM')}>
                  Chấm điểm
                </DropdownItem>
                <DropdownItem onClick={()=>this.createNewOne('XO_SO')}>
                  Xổ số
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink className="nav-link" to={APPPEOPLE}>Thành viên</NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {user.name}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Option 1
                </DropdownItem>
                <DropdownItem>
                  Option 2
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={()=>this.props.logoutUser()}>
                  Đăng xuất
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

Dashboard.propTypes = {
  createNew: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps,{logoutUser,createNew})(Dashboard));
