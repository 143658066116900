import React, { Component } from 'react'
import isEmpty from '../../validation/is-empty'
import {Row,Col,Media} from 'reactstrap'
import randomize from 'randomatic'

class userScreen extends Component {
  constructor(){
    super()
    this.state={
      RandNum:0,
      Flag:true,
      Save:false,
      peopleNum:0,
      num:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0},
      Prize:0,
      Group:[],
      count:[]
    }
    this.keypush = this.keypush.bind(this)
  }
  componentDidMount(){
    document.addEventListener("keydown",this.keypush,false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown",this.keypush,false);
  }
  static getDerivedStateFromProps(props,state){
    if(!isEmpty(props.event.XoSo.Group)){
      if(state.Group.length ===0){
        for (let index = 0; index < props.event.XoSo.Group.length; index++) {
          state.Group.push(0)
        }
        return state
      }
    }
    if(!isEmpty(props.event.XoSo.Prize)){
      if(isEmpty(state.count)){
        for (let i = 0; i < props.event.XoSo.Prize.length; i++) {
          state.count.push(0)
        }
      }
    }
  }
  keypush(e){
    switch (e.keyCode) {
      case 32:
        if(!isEmpty(this.props.event.XoSo.People)){
          if(this.props.event.XoSo.People.length === this.props.event.XoSo.Result.length+1){
            alert("Hết số rồi !!!")
          }
          else{
            if(this.state.Flag){ 
              this.setState({Flag:false})
              let start = ''
              let end = ''
              for (let i = 0; i < this.props.event.XoSo.Ranges.length; i++) {
                start+=this.props.event.XoSo.Ranges[i][0]
                end+=this.props.event.XoSo.Ranges[i][1]
              }
              if(this.props.event.XoSo.People.length>0){
                end = parseInt(start) + this.props.event.XoSo.People.length-2
              }
              
              this.setState({RandNum:this.runRandom(parseInt(start),parseInt(end))},()=>{
                for (let i = 1; i <= this.state.RandNum.toString().length; i++) {
                  this.animateValue(0,parseInt(this.state.RandNum.toString()[i-1])+(100*i),(2000*i),i-1)
                }
                let count = this.state.count
                count[this.state.Prize] +=1
                if(this.props.event.XoSo.People.length>0){
                  const func = function() {
                    this.setState({peopleNum:parseInt(this.state.RandNum) - parseInt(start)+1,count},()=>{
                      this.setState({Save:true})
                    })
                  }.bind(this)
                  setTimeout(func, 2000*(this.state.RandNum.toString().length+2));
                }
              })
            }
          }
        }
        break;
      // >>>>>>
      case 39:
        if(this.state.Prize +1 === this.props.event.XoSo.Prize.length){
          this.setState({Prize:0})
        }else{
          this.setState({Prize:this.state.Prize+1})
        }
        break;
      // <<<<<<
      case 37:
        if(this.state.Prize  === 0){
          this.setState({Prize:this.props.event.XoSo.Prize.length-1})
        }else{
          this.setState({Prize:this.state.Prize-1})
        }
        break;
      case 13:
        if(e.shiftKey){
          if(this.state.Save){
            this.props.saveXoSo({Num:this.state.RandNum,Prize:this.state.Prize})
            this.setState({RandNum:0,peopleNum:0,num:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0},Flag:true,Save:false})
          }
        }
        break;
      default:
        break;
    }
  }
  runRandom=(begin,end)=>{
    const {Group,Result,People,FW} = this.props.event.XoSo
    let random = randomize('0',end.toString().length)
    if(!isEmpty(FW)){
      if(this.state.Prize === FW.Prize){
        if(Result.filter(x => x.No ===FW.random).length ===0){
          if(Result.filter(x => x.Prize ===FW.Prize).length===FW.count){
            return FW.random
          }
        } else{
          if(random === FW.random){
            return this.runRandom(begin,end)
          }
        }
      }
    }
    if(random >=begin && random <= end){
      if(Result.filter(x => parseInt(x.No) === parseInt(random)).length >0){
        return this.runRandom(begin,end)
      } else {
        
        if(isEmpty(Group)){
          return random
        }else{
          let flag=0
          let equa = 0
          for (let i = 0; i < Group.length; i++) {
            if(Group[i].indexOf(People[random-begin+1].DonVi)!==-1){
              for(let j=0;j<this.state.Group.length;j++){
                if(i!==j){
                  if(this.state.Group[i] < this.state.Group[j]){
                    flag+=1
                  }
                  if(this.state.Group[i] === this.state.Group[j]){
                    equa +=1
                  }
                }
              }
              if(flag>0 || equa === this.state.Group.length-1){
                let Groupz = this.state.Group
                Groupz[i]+=1
                this.setState({Group:Groupz})
                return random
              } else{
                return this.runRandom(begin,end)
              }
              break;
            }
          }
          
        }
        
        
      }
    } 
    return this.runRandom(begin,end)
  }
  animateValue(start,end, duration,ind) {
    let range = end - start;
    let current = start;
    let increment = end > start? 1 : -1;
    const step = function() {
      current += increment;
      this.setState(pre => ({
        ...pre,
        num:{
          ...pre.num,
          [ind]:current.toString().split('').pop()
        }
      }))
      if (current !== end) {
        setTimeout(step, ((duration * 4) / Math.pow(range, 3)) * Math.pow(current, 2));
      }
    }.bind(this);
    setTimeout(step, ((duration * 4) / Math.pow(range, 3)) * Math.pow(start, 2));
  }
  render() {
    const {event} = this.props
    return (
      <Col style={{height:'100vh'}}>
        <Row style={{height:'20vh'}} className="pt-3 pb-3">
          <Col xs={3} className="d-flex flex-column justify-content-center align-items-center mh-100" >
            <Media style={{maxWidth:"60%",maxHeight:'60%'}} src={isEmpty(event.MainLayout.HeaderLeft) ? "" : event.MainLayout.HeaderLeft}/>
          </Col>
          <Col xs={6} className="d-flex flex-column justify-content-center align-items-center mh-100">
            <Media style={{maxWidth:"100%",maxHeight:'100%'}} src={isEmpty(event.MainLayout.HeaderCenter) ? "" : event.MainLayout.HeaderCenter}/>
          </Col>
          <Col xs={3}>
            <Media style={{maxWidth:"60%",maxHeight:'60%'}} src={isEmpty(event.MainLayout.HeaderRight) ? "" : event.MainLayout.HeaderRight}/>
          </Col>
        </Row>
        <Row style={{height:'70vh'}}>
          <Col xs ={4} className="d-flex flex-column justify-content-center align-items-center mh-100">
            <Media style={{maxHeight:'100%'}} src={isEmpty(event.XoSo.Prize) ? "" : event.XoSo.Prize[this.state.Prize]}/>
          </Col>
          <Col xs={8} className="d-flex flex-column justify-content-center align-items-center mh-100 mw-100">
            <Row className="w-100 h-100">
              <Col xs={{size:8,offset:2}} className="d-flex flex-column justify-content-end align-items-center w-100 mh-100">
                <Row className="w-100 h-100">
                  {event.XoSo.Ranges.map((range,i) => 
                    <Col 
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{background:`url(https://photos.hunghau.holdings/hhh/hexagon.png)no-repeat center center`,backgroundSize:'100% auto'}}>
                      <h1 className="text-center text-light" style={{fontSize:'5vw'}}><strong>{this.state.num[i]}</strong></h1>
                    </Col> 
                  )}
                </Row>
                <Row style={{height:'35vh',marginTop:'-10vh'}}>
                  <Col xs='12' >
                    <Row className="align-items-center justify-content-center"> 
                      <h4 style={{
                        background:'-webkit-linear-gradient(#f7be2f, #f6ce76)',
                        "-webkit-background-clip":'text',
                        "-webkit-text-fill-color":'transparent'}}>{isEmpty(event.XoSo.People) ? '':'XIN CHÚC MỪNG QUÝ ANH/CHỊ' }  </h4> 
                    </Row>
                    <Row className="align-items-center justify-content-center"> 
                      <h2 className="text-light" style={{fontWeight:'bold'}}> {isEmpty(event.XoSo.People) ? "" :event.XoSo.People[this.state.peopleNum].Ten}</h2> 
                    </Row>
                    <Row className="align-items-center justify-content-center"> 
                      <h4 className="text-light" style={{FontColor:'#ccc'}}>{isEmpty(event.XoSo.People) ? "" : event.XoSo.People[this.state.peopleNum].DonVi} </h4>
                    </Row>
                    <Row className="align-items-center justify-content-center"> 
                      <h4 className="text-light" style={{FontColor:'#ccc'}}>{isEmpty(event.XoSo.People) ? "" : event.XoSo.People[this.state.peopleNum].Email} </h4>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        

      </Col>
    )
  }
}
export default userScreen