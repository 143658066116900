import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';

import {
  LOGIN,
  REGISTER,
  APPHOME,
  LANDING,
  EVENTS,
  RESULT,
  BINHCHON,
  SHORTURL,
  APPPEOPLE,
  DANHGIA,
  KETQUA,
  XOSO
} from './actions/routes'

import { Provider } from 'react-redux';
import store from './store';

import Landing from './components/layout/Landing';

import LandingPage from './pages/landingPage'
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import UserView from './components/UserView'
import Result from './components/UserView/Result'
import NotFound from './components/not-found/NotFound';

import Navbar from './components/layout/Navbar'
import Dashboard from './components/dashboard/Dashboard'
import Peoples from './components/Peoples'
import DanhGia from './components/DanhGia'
import BinhChon from './components/BinhChon'
import EventView from './components/EventView'
import KetquaView from './components/KetquaView'
import XoSo from './components/XoSo'

import './App.css';

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    // Redirect to login
    window.location.href = LOGIN;
  }
}



const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)

const AdminLayout = props => (
  <React.Fragment>
    <Navbar/>
      {props.children}
  </React.Fragment >
)

const UserLayout = props => (
  <React.Fragment>
    {props.children}
  </React.Fragment>
)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App vh-100 vw-100">
            <AppRoute path={EVENTS} component={UserView} layout={UserLayout} />
            <AppRoute path={RESULT} component={Result} layout={UserLayout} />
            <AppRoute exact path={LANDING} component={LandingPage} layout={UserLayout} />
            <AppRoute exact path={REGISTER} component={Register} layout={UserLayout} />
            <AppRoute exact path={LOGIN} component={Login} layout={UserLayout} />
            <AppRoute path={SHORTURL} component={EventView} layout={UserLayout}/>
            <Switch>
              <AppRoute exact path={KETQUA} component={KetquaView} layout={UserLayout} />
              <AppRoute exact path={APPHOME} component={Dashboard} layout={AdminLayout} />
              
              <AppRoute exact path={DANHGIA} component={DanhGia} layout={AdminLayout} />
              <AppRoute exact path={BINHCHON} component={BinhChon} layout={AdminLayout} />
              <AppRoute exact path={XOSO} component={XoSo} layout={AdminLayout} />
              <AppRoute exact path={APPPEOPLE} component={Peoples} layout={AdminLayout} />
            </Switch>
            <AppRoute exact path="/thanks-you" component={NotFound} layout={UserLayout} />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
