import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from '../../validation/is-empty'
import {Row,Container,Spinner,Col} from 'reactstrap'

import {getPeopleAll} from '../../actions/peopleAction'
import {
  getCurrentEventID,
  updateEvent,
  AddBinhChon,
  updateGroupBinhChon,
  updateBinhChonGK,
  saveEvents,
  getShortLink,
  setResetPoint,
  getResults
} from '../../actions/eventActions'

import SideBar from './SideBar'
import Screen from './Screen'
import ShortURL from '../layout/ShortURL'
import ResultScreen from './resultScreen'

class index extends Component {
  componentDidMount(){
    this.props.getPeopleAll()
    this.props.getCurrentEventID(localStorage.getItem("EventID"));
  }
  constructor(){
    super()
    this.state={
      current:0,
      screen:'Giao_Dien_Ng_Dung'
    }
    this._current = this._current.bind(this)
    this._actionScreen = this._actionScreen.bind(this)
    this._updateGroupBinhChon = this._updateGroupBinhChon.bind(this)
  }
  _current=(current)=>{
    this.setState({current})
  }
  _updateContent=(data)=>{
    this.props.updateEvent(data)
  }
  _actionScreen=(screen)=>{
    switch (screen) {
      case 'Giao_Dien_Ng_Dung':
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        break;
      case 'Luu_Noi_Dung':
        this.props.saveEvents(this.props.events.event)
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        break;
      case "Lay_Duong_Dan":
          this.setState({screen:"Lay_Duong_Dan"})
          this.props.getShortLink(this.props.events.event._id)
        break;
      case 'Xem_Ket_Qua':
          this.props.getResults(localStorage.getItem("EventID"))
          this.setState({screen:"Xem_Ket_Qua"})
        break;
      case 'Xoa_Ket_Qua':
        this.setState({screen:"Giao_Dien_Ng_Dung"})
        this.props.setResetPoint(this.props.events.event._id)
        break;
      default:
        break;
    }
  }
  _AddBinhChon = (data) => {
    this.props.AddBinhChon(data)
  }
  _updateGroupBinhChon = (Name,Value) => {
    console.log(Name,Value)
    this.props.updateGroupBinhChon({Current:this.state.current,Name,Value})
  }
  _updateCurrent = current => {
    this.setState({current})
  }
  _updateBinhChonGK = (data) => {
    this.props.updateBinhChonGK(data)
  }
  render() {
    const {event,results,ShortLink,loading} = this.props.events
    let Content
    if(isEmpty(event) | loading){
      Content= 
        <Row style={{height:'80vh'}}>
          <Col className="d-flex justify-content-md-center align-items-center">
            <Spinner color="primary" />
          </Col>
        </Row>
      
    } else {
      Content=
          <Row className="flex-xl-nowrap">
            <SideBar
              event={event}
              peoples={this.props.peoples.Peoples} 
              current={this.state.current}
              AddBinhChon = {this._AddBinhChon}
              updateBinhChon = {this._updateGroupBinhChon}
              updateCurrent = {this._updateCurrent}
              actionScreen = {this._actionScreen}
              updateBinhChonGK= {this._updateBinhChonGK}
              updateContent = {this._updateContent}
            />
            {this.state.screen === 'Giao_Dien_Ng_Dung' ? 
              <Screen 
                event={event}
                current={this.state.current}
              /> : ""
            }
            {this.state.screen === 'Lay_Duong_Dan' ? 
              <ShortURL 
                results={ShortLink}
              /> : ""
            }
            {this.state.screen === 'Xem_Ket_Qua' ? 
              <ResultScreen 
                results={results}
              /> : ""
            }
          </Row>
    }
    return (
      <Container fluid>
        {Content}
      </Container>
    )
  }
}

index.propTypes = {
  peoples: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  peoples: state.peoples,
  auth: state.auth,
  events: state.events,
  errors: state.errors
});

export default connect(mapStateToProps,{
  getPeopleAll,
  getCurrentEventID,
  updateEvent,
  AddBinhChon,
  updateGroupBinhChon,
  updateBinhChonGK,
  saveEvents,
  getShortLink,
  setResetPoint,
  getResults
})(index)