import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewContents from '../MainContent/ViewContents';
import {getEvents} from '../../actions/eventActions'
import isEmpty from '../../validation/is-empty'
import {
  Container, Row, Col
 } from 'reactstrap'

class Dashboard extends Component {
  componentDidMount() {
    this.props.getEvents();
  }
  constructor(){
    super()
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      DataObject:{
        BackGround:{
          type:'',
          value:'#ccc'
        }
      }
    };
  } 
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const {loading,events} = this.props.events
    let content = (
      <Col xs="12" className="text-center">
        <h4>Dữ liệu trống</h4>
      </Col>
      
    );
    if(!(loading|isEmpty(events))){
      content = (
        <ViewContents/>
      )
    }
    return (
      <Container fluid>
        <Row>
          {content}
        </Row>
      </Container>
    );
  }
}
 
Dashboard.propTypes = {
  getEvents: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  events: state.events
});

export default connect(mapStateToProps,{getEvents})(Dashboard);
