import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {Table,Container,Spinner,Row,Col,Media,Modal } from 'reactstrap'
import {getresultsToken} from '../../actions/eventActions'
import isEmpty from '../../validation/is-empty';
import {CSSTransition} from 'react-transition-group'
import '../../img/style.css'

class Results extends Component {
  constructor(){
    super()
    this.state={
      data:[
      ],
      result:[],
      winner:null,
      AnimationNumber:0,
      animate:false,
      modal:false,
      URLWINNER:[
        {Name:'Liên minh HHO và HHC',Value:"http://media.hunghau.holdings/medias/HHO---HHC.png"},
        {Name:'Liên minh HHD và HHF',Value:"http://media.hunghau.holdings/medias/HHF---HHD.png"},
        {Name:'VHU',Value:"http://media.hunghau.holdings/medias/VHU.png"},
        {Name:'HHA',Value:"http://media.hunghau.holdings/medias/HHA.png"},
        {Name:'HHE',Value:"http://media.hunghau.holdings/medias/HHE.png"},
      ]
    }
    this.keypush=this.keypush.bind(this)
    this.toggle = this.toggle.bind(this)
  }
  componentDidMount(){
    this.props.getresultsToken({token:this.props.match.params.token})
    document.addEventListener("keydown",this.keypush,false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown",this.keypush,false);
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  keypush(e){
    switch (e.keyCode) {
      case 32:
        this.setState({animate:true})
        let data = this.state.data
        const current = this.state.AnimationNumber
        if(current < this.state.data.length){
          data[
            data.findIndex(ar => ar.Name === this.state.result[current].Name)
          ].Vong3 = this.state.result[current].Vong3
          /*
          data.sort((a,b)=>{
            const tonga = a.Vong1 + a.Vong2 + a.Vong3
              const tongb = b.Vong1 + b.Vong2 + b.Vong3
              if(tonga>tongb){
                return -1
              }
              return 1
          })
          
          console.log(this.state)*/
          this.setState({data,AnimationNumber:current+1})
          if(this.state.AnimationNumber=== data.length){
            this.setState({data:data.sort((a,b)=>{
              const tonga = a.Vong1 + a.Vong2 + a.Vong3
              const tongb = b.Vong1 + b.Vong2 + b.Vong3
              if(tonga>tongb){
                return -1
              }
              return 1
            }),AnimationNumber:current+1})
          }
        }else{
          this.setState({modal:true})
        }
        this.setState({animate:false})
        console.log(this.state.result)
        break;
    
      default:
        break;
    }
  }
  componentWillReceiveProps (props){
    if(!isEmpty(props.events.event.EventType)){
      let data=[]
      let sum=0
      props.events.event.data.map(result => {
        sum=0
        if(!isEmpty(result.Main)){
          result.Value.map(cont => sum+= parseInt(cont.Value))
          data.push({
            Name:result.subMain,
            Vong1:result.Vong1 * 2,
            Vong2:result.Vong2 * 3.5,
            Vong3:0,
            Vong3b: sum*0.1/ parseInt(props.events.event.GiamKhao.length),
            Tong:0
          })
        }
        return{
          result
        }
      })
      data.sort((a,b)=>{
        if(a.Vong3b>b.Vong3b){return -1}
        return 1
      })
      let no = data[0].Vong3b
      let i =10
      data.map(kq=>{
        if(kq.Vong3b===no){
          kq.Vong3 = i *4.5
        } else {
          i = i-1
          kq.Vong3 = i *4.5
        }
        kq.Tong = kq.Vong1+kq.Vong2+(i*4.5)
        no = kq.Vong3b
        return {kq}
      })
      const winner = data.sort((a,b)=>{
                      if(a.Tong>b.Tong){
                        return -1
                      }
                      return 1
                    })[0].Name
      
      this.setState({
        data:data.map(kq=>({Name:kq.Name,Vong1:kq.Vong1,Vong2:kq.Vong2,Vong3:0})),
        result:data.sort((a,b)=>{
          if(a.Vong3>b.Vong3){
            return 1
          }
          return -1
        }),
        winner:this.state.URLWINNER.filter( resu => resu.Name === winner)[0].Value
      })
      
    }
  }
  
  render() {
    const {loading,event} = this.props.events
    let content;
    if(loading|isEmpty(event.EventType)){
      content = (
        <Spinner color='primary'/>
      )
    } else {
      content = (
        <Col xs="9">
          <Table bordered striped>
            <thead>
              <tr style={{backgroundColor:'rgba(129, 192, 65,0.7)'}}>
                <th className="text-white text-center" style={{fontSize:'1.75rem'}}>ĐỘI</th>
                <th className="text-white text-center" style={{fontSize:'1.75rem'}}>VÒNG 1</th>
                <th className="text-white text-center" style={{fontSize:'1.75rem'}}>VÒNG 2</th>
                <th className="text-white text-center" style={{fontSize:'1.75rem'}}>VÒNG 3</th>
                <th className="text-white text-center" style={{fontSize:'1.75rem'}}>TỔNG</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((result,index) => (
                <CSSTransition in={this.state.animate} timeout={200} classNames="my-node">
                  <tr style={(index-1)%2===0?{backgroundColor:'rgba(0,0,0,0.6)'}:null}>
                    <td style={{fontSize:'1.75rem',color:'white',animation:"bind 1s linear;"}}>
                      {result.Name}
                    </td>
                    <td className="text-white text-center" style={{fontSize:'1.75rem'}}>{result.Vong1}</td>
                    <td className="text-white text-center" style={{fontSize:'1.75rem'}}>{result.Vong2}</td>
                    <td className="text-white text-center" style={{fontSize:'1.75rem'}}>{result.Vong3}</td>
                    <td className="text-white text-center" style={{fontSize:'1.75rem'}}>{(result.Vong1) + (result.Vong2) + (result.Vong3)}</td>
                  </tr>
                </CSSTransition>
                
              ))}
            </tbody>
          </Table >
        </Col>
        
      )
    }
    return (
      <Container fluid>
        <Row>
        <Col xs="12" className="flex-xl-nowrap vh-100 vw-100" 
          style={{
            backgroundImage:`url(${event.background})`,
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            backgroundSize:"cover"}}
        >
          <Row style={{paddingTop:'10vh'}}>
            <Col xs="2" style={{height:'10vh'}} className="text-right">
              <Media style={{height:'120%'}} object src={event.HeaderLeft} alt="Left Logo" />
            </Col>
            <Col xs="8" className="text-center" style={{height:'8vh'}}> 
              <Media style={{height:'100%'}} object src={event.HeaderCenter} alt="Center Logo" />
            </Col>
            <Col xs="2" style={{height:'10vh'}} className="text-left">
              <Media style={{height:'120%'}} object src={event.HeaderRight} alt="Right Logo" />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center" style={{height:"70vh"}}>
            {content}
          </Row>
        </Col>
        </Row>
        <Modal isOpen={this.state.modal} toggle={this.toggle}  style={{
          height:'60vh',
          backgroundImage:`url('${this.state.winner}')`
          ,backgroundPosition:'center',backgroundRepeat:'no-repeat',
          backgroundSize:'contain'
          
          }}>
          
        </Modal>
      </Container>
      )
  }
}

Results.propTypes = {
  getresultsToken: PropTypes.func.isRequired,
  events: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  events: state.events
});

export default connect(mapStateToProps,{getresultsToken})(Results)
